import { useEffect } from 'react'
import Layout from '../layouts/Layout'
import { Mixpanel } from '../mixPanel/mixPanel'
import Logos from '../components/logos'
import FooterMenu from '../components/FooterMenu'
import { CheckIcon } from '@heroicons/react/solid'
import { useMatch } from 'react-router-dom'

declare global {
  interface Window {
    Paddle: any;
    onPaddleSuccess: any;
    onPaddleClose: any;
  }
}
const Checkout = () => {
  const productId: any = useMatch('/checkout/:product_id')

  const tier =   {
    name: 'Dataloop - 6 months',
    max_seats: 30,
    max_records: 10000,
    max_data_storage: 365,
    price: 5700,
    record_capture: true,
    record_analytics: true,
    record_ai: true
  }

  useEffect(() => {
    Mixpanel.track('dataloop checkout Page Viewed', {})
    openCheckout(productId.params.product_id) 
  }, [])
  const Paddle = window.Paddle
  
  const openCheckout = (product: number) => {
    Paddle.Checkout.open({
      product: product,
      closeCallback: 'onPaddleClose',
      successCallback: 'onPaddleSuccess',
      disableLogout: true,
      // method: 'inline'
    })
  }

  const featuresIncluded = (tier: any) => {
    return (
      <ul role="list" className="mt-6 space-y-4">
        <li className="flex space-x-3">
          <CheckIcon
            className="h-5 w-5 flex-shrink-0 text-green-500"
            aria-hidden="true"
          />
          <span className="text-sm text-gray-500">{tier.max_seats} seats</span>
        </li>
        <li className="flex space-x-3">
          <CheckIcon
            className="h-5 w-5 flex-shrink-0 text-green-500"
            aria-hidden="true"
          />
          <span className="text-sm text-gray-500">
            {tier.max_records} Records per month
          </span>
        </li>
        <li className="flex space-x-3">
          <CheckIcon
            className="h-5 w-5 flex-shrink-0 text-green-500"
            aria-hidden="true"
          />
          <span className="text-sm text-gray-500">
            {tier.max_data_storage} days Data storage limit
          </span>
        </li>
        {tier.record_capture && (
          <li className="flex space-x-3">
            <CheckIcon
              className="h-5 w-5 flex-shrink-0 text-green-500"
              aria-hidden="true"
            />
            <span className="text-sm text-gray-500">Record capture</span>
          </li>
        )}

        {tier.record_ai && (
          <li className="flex space-x-3">
            <CheckIcon
              className="h-5 w-5 flex-shrink-0 text-green-500"
              aria-hidden="true"
            />
            <span className="text-sm text-gray-500">Record AI</span>
          </li>
        )}
        {tier.record_analytics && (
          <li className="flex space-x-3">
            <CheckIcon
              className="h-5 w-5 flex-shrink-0 text-green-500"
              aria-hidden="true"
            />
            <span className="text-sm text-gray-500">Record Analytics</span>
          </li>
        )}
      </ul>
    )
  }

  return (
    <Layout withCircles={true}>
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:p-8">
        <div className="sm:flex sm:flex-col ">
          <h1 className="text-5xl text-center font-bold tracking-tight text-gray-900 ">
            Pricing Plans
          </h1>
          <p className="mt-5 text-xl text-center text-gray-500">
          Upgrade your CX team with Record - faster resolution times are just few clicks away.
          </p>
        </div>
        {/* <div className="mt-12 space-y-4 sm:mt-16 sm:grid sm:grid-cols-2 sm:gap-6 sm:space-y-0 lg:mx-auto lg:max-w-4xl xl:mx-0 xl:max-w-none xl:grid-cols-4">
          <div
            key={tier.name}
            className={`divide-y border ${
              false ? 'border-tuto-purple' : 'border-gray-200'
            } divide-gray-200 rounded-lg bg-white bg-opacity-60 shadow-sm relative overflow-hidden`}
          >
            <div className="p-6">
              <h2 className="text-lg font-medium leading-6 text-gray-900 capitalize">
                {tier.name}
              </h2>
              <p className="mt-8">
                <span className="text-4xl font-bold tracking-tight text-gray-900">
                  ${tier.price}
                </span>
              </p>

              <button
                onClick={() => {
                  Mixpanel.track('user clicked on start for free', {
                    from: tier.name
                  }) 
                  openCheckout(816062) //prod - 816062, sandbox - 46271
                }}
                className="mt-8 block w-full rounded-md border capitalize border-normal-black bg-normal-black py-2 text-center text-sm font-semibold text-white hover:bg-gray-900"
              >
                Pay
              </button>
            </div>
            <div className="px-6 pt-6 pb-8">
              <h3 className="text-sm font-medium text-gray-900">
                What's included
              </h3>
              <ul role="list" className="mt-6 space-y-4">
                {featuresIncluded(tier)}
              </ul>
            </div>
          </div>
        </div> */}
        {/* <div className='mt-12'>
          <Logos />
        </div>
        <FooterMenu/> */}
      </div>
    </Layout>
  )
}

export default Checkout
