import FeatureSection from '../components/FeatureSection'
import Layout from '../layouts/Layout'
import { Mixpanel } from '../mixPanel/mixPanel'
import { useEffect } from 'react'
import Testimonials from '../components/Testimonials'
import ABtestHero from '../components/ABtestHero'
import Tabs from '../components/Tabs'
import { tabsContent } from '../content/tabs'
import ProductHuntBadge from '../components/ProductHuntBadge'
import LogosNoCarousel from '../components/LogosNoCarousel'
import Badges from '../components/Badges'


const Home = () => {
  useEffect(() => {
    Mixpanel.track('Home Page View', {})
  }, [])
  return (
    <Layout withCircles={true}>
      {/* <ProductHuntBadge post_id={404298} theme="light" width={250} height={54} /> */}
      <Badges />
      <ABtestHero />
      <LogosNoCarousel />
      {/* <Logos /> */}
      {/* <Stats /> */}
      <FeatureSection />
      <Tabs tabs={tabsContent}/>
      {/* <Integrations /> */}
      {/* <Video /> */}

      <Testimonials />
      {/* <Carousel /> */}

      {/* <Modal
        isOpen={open}
        setOpen={setOpen}
        isVideo={false}
        popupEvent={true}
      /> */}
    </Layout>
  )
}

export default Home
