import React from 'react'

const LogosNoCarousel = () => {
  return (
    <div className='flex gap-y-4 items-center gap-x-2  flex-wrap lg:gap-16 lg:gap-x-36  mx-auto container justify-around max-w-4xl relative top-2 bottom-20 md:bottom-40 mb-24'>
      <div className="w-1/3  flex justify-center items-center lg:w-auto">
        <img
          className="h-6 "
          src="https://komodor.com/wp-content/uploads/2023/07/komodor-logo-new.svg"
          alt="Tuple"
        />
      </div>
      <div className="w-1/3 flex justify-center lg:w-auto">
        <img
          className="h-8   anodot"
          src="https://assets.torecord.it/dataloop-logo.png"
          alt="Mirage"
        />
      </div>
      <div className="w-1/3 flex justify-center lg:w-auto">
        <img
          className="h-6  bg-white"
          src="https://assets.torecord.it/sparc-logo.png"
          alt="Workcation"
        />
      </div>
      <div className="w-1/3 flex justify-center lg:w-auto">
        <img
          className="h-14  transform"
          src="https://assets.torecord.it/lrn-logo.png
          "
          alt="Transistor"
        />
      </div>
      <div className="w-1/3 flex justify-center lg:w-auto">
        <img
          className="h-9 "
          src="https://assets.torecord.it/group-gps-logo.png"
          alt="Workcation"
        />
      </div>
      <div className="w-1/3 flex justify-center lg:w-auto">
        <img
          className="w-56 "
          src="https://assets.torecord.it/scrimmage-logo.png"
          alt="StaticKit"
        />
      </div>
      
    </div>
  )
}

export default LogosNoCarousel
