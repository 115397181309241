import Layout from '../layouts/Layout'
import { MailIcon } from '@heroicons/react/solid'
import linkedin from '../assets/linkedin.svg'
import { Mixpanel } from '../mixPanel/mixPanel'
import { useEffect } from 'react'

const team = [
  {
    name: 'Ohad Ronen',
    position: 'Co-founder & CEO',
    linkedin: 'https://www.linkedin.com/in/ohadnr/',
    email: 'ohad.ronen@tutoit.io',
    imgSrc: 'https://assets.torecord.it/about-us-ohad.png'
  },
  {
    name: 'Tom Brachel',
    position: 'Co-founder & CTO',
    linkedin: 'https://www.linkedin.com/in/tom-brachel-75252a174/',
    email: 'tom.brachel@tutoit.io',
    imgSrc: 'https://assets.torecord.it/about-us-tom.png'
  },
  {
    name: 'Gili Sinai',
    position: 'VP R&D',
    linkedin: 'https://www.linkedin.com/in/gili-sinai/',
    email: 'gili.sinai@tutoit.io',
    imgSrc: 'https://assets.torecord.it/about-us-gili.png'
  },
  {
    name: 'Shai Israeli',
    position: 'Full Stack Engineer',
    linkedin: 'https://www.linkedin.com/in/shai-israeli-39884110b/',
    email: 'shai.israeli@tutoit.io',
    imgSrc: 'https://assets.torecord.it/about-us-shai.png'
  }
]

const AboutUs = () => {
  useEffect(() => {
    Mixpanel.track(`About us page viewd`, {})
  }, [])
  return (
    <Layout withCircles={true}>
      <div className="container p-4 md:p-0 mx-auto my-16">
        <div className="flex flex-col items-center justify-center text-center my-40">
          <h2 className=" mb-8 text-4xl sm:text-5xl font-extrabold tracking-tight font-montserrat">
            What Is Our Record?
          </h2>
          <p className="mt-4 text-base sm:text-2xl  px-12">
            Today as customers and employees we expect so much more. 
            We look for smooth interactions and fast value.
             Our team has 14 years experience in software, customer service and error handling.
            If we weren’t software engineers, we probably would have been bug farmers.
          </p>
        </div>
        <div className="grid grid-cos-1 md:grid-cols-2 mt-20 items-center">
          <div className="p-8">
            <img
              src="https://assets.torecord.it/tuto4.jpg"
              className="rounded shadow-xl min-w-full"
            />
          </div>
          <div className="mt-8 md:mt-0">
            <h1 className="text-tuto-fifth text-3xl sm:text-4xl mb-4">2021</h1>
            <h2 className=" text-4xl sm:text-5xl font-extrabold tracking-tight font-montserrat">
              We Start To
              <span className="text-tuto-primary"> Record.</span>
            </h2>
            <p className="mt-4 text-base sm:text-2xl ">
              We started our journey as tuto, helping companies create a better
              how-to for their customers. With this idea, we have been selected
              from 400 companies to be a Techstars company and everything
              started. We push on Record
            </p>
          </div>
        </div>
        <div className="grid grid-cos-1 md:grid-cols-2 mt-20 items-center">
          <div>
            <h1 className="text-tuto-fifth text-3xl sm:text-4xl mb-4">2021</h1>
            <h2 className="text-4xl sm:text-5xl font-extrabold tracking-tight font-montserrat">
              <span className="text-tuto-secondary"> Backed By </span>
              Amazing Vc’s And Angels Investors
            </h2>
            <p className="mt-4 text-base sm:text-2xl ">
              After hard work we got the opportunity to be part of amazing
              investors who invest in companies like: Notion, Deel, Tinder,
              Ramp, Lyft, and much more. <br /> <br /> And leading executives
              from big companies like: Deel, Bringg, Workday, YC, UI-Path
            </p>
          </div>
          <div className="p-8 md:p-20">
            <img src="https://assets.torecord.it/aboutUsOne.png" className="" />
          </div>
        </div>
        <div className="flex flex-col-reverse md:grid grid-cos-1 md:grid-cols-2 mt-20 items-center">
          <div className="p-16">
            <img
              src="https://assets.torecord.it/3-logos-about.png"
              className=""
            />
          </div>
          <div>
            <h1 className="text-tuto-fifth text-3xl sm:text-4xl mb-4">2022</h1>
            <h2 className="text-4xl sm:text-5xl font-extrabold tracking-tight font-montserrat">
              <span className="text-tuto-fifth"> Amazing </span>
              Companies Supporters
            </h2>
            <p className="mt-4 text-base sm:text-2xl ">
              The real partnership makes it all happen. We got the opportunity
              to onboard and learn from leading companies to create the best
              support tool ever made.
            </p>
          </div>
        </div>
        <div>
          <div className="mb-12 mt-20">
            <h1 className="text-tuto-fifth text-3xl sm:text-4xl mb-4">
              The Team
            </h1>
            <h2 className="text-normal-black text-4xl sm:text-5xl font-extrabold tracking-tight font-montserrat">
              Who Made It Possible
            </h2>
          </div>
          <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
            {team.map((member) => (
              <div key={member.name}>
                <div>
                  <img src={member.imgSrc} className="teammate-pic" />
                </div>
                <div className="mt-4">
                  <div>
                    <h2 className="text-xl font-semibold">{member.name}</h2>
                    <p>{member.position}</p>
                  </div>
                  <div className="flex my-4">
                    <a
                      target={'_blank'}
                      className="p-1 rounded-full bg-tuto-third mr-2"
                      href={member.linkedin}
                      onClick={() => {
                        Mixpanel.track(
                          `User clicked on ${member.name} linkedin`,
                          {}
                        )
                      }}
                    >
                      <img className="w-5 h-5" src={linkedin} />
                    </a>
                    <a
                      className="p-1 rounded-full bg-tuto-third mr-2"
                      href={`mailto:${member.email}`}
                      onClick={() => {
                        Mixpanel.track(
                          `User clicked on ${member.name} mail`,
                          {}
                        )
                      }}
                    >
                      <MailIcon className="w-5 h-5" />
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default AboutUs
