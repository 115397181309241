import React, { useEffect, useState } from 'react'
import { Mixpanel } from '../mixPanel/mixPanel'
import TextTransition, { presets } from 'react-text-transition'
import StringSwitcher from './StringSwitcher'
import ColoredTitle from './ColoredTitle'
const Experiment = require('react-ab-test/lib/Experiment')
const Variant = require('react-ab-test/lib/Variant')
const emitter = require('react-ab-test/lib/emitter')

emitter.defineVariants('Hero-Title', ['Resolve', 'Intelligent'], [50, 50])

const ABtestTitle = () => {
  const TEXTS = ['Win technical issues', 'Makes a cross-department impact']

  const [index, setIndex] = useState(0)

  useEffect(() => {
    const intervalId = setInterval(
      () => setIndex((index) => index + 1),
      5000 // every 3 seconds
    )
    return () => clearTimeout(intervalId)
  }, [])

  return (
    <Experiment name="Hero-Title">
      <Variant name="Resolve">
        {/* <StringSwitcher strings={['All The Data You Need For Any Ticket.']} /> */}
        {/* <p className="my-6 text-center text-black text-base md:text-lg lg:text-xl xl:text-2xl">
          AI Powered Customer Support layer for B2B SaaS.
        </p> */}
        <ColoredTitle title='Saves Expensive Time Spent on SaaS Technical Issues' wordsNumberToEmphasize={[2,3]} />
        {/* <h1 className="mb-6   text-normal-black text-center text-5xl font-extrabold font-montserrat sm:mt-5 sm:text-6xl lg:mt-4 xl:text-7xl leading-normal">
          
          <span className="block  mb-2">Resolve user issues in</span>
          <span className="block text-tuto-primary"> minutes</span>
        </h1> */}
      </Variant>
      <Variant name="Intelligent">
      {/* <p className="my-6 text-center text-black text-base md:text-lg lg:text-xl xl:text-2xl">
          Customer Support Layer, Built for Technical Teams
        </p> */}
      {/* <StringSwitcher strings={['All The Data You Need For Any Ticket']} /> */}
      <ColoredTitle title='Saves Expensive Time Spent on SaaS Technical Issues' wordsNumberToEmphasize={[2,3]} />
        {/* <h1 className="mb-6 text-normal-black text-center text-5xl font-extrabold font-montserrat sm:mt-5 sm:text-6xl lg:mt-6 xl:text-7xl">
          <span className="block  mb-2">Resolve user issues in</span>
          <span className="block text-tuto-primary"> minutes</span>
        </h1> */}
      </Variant>
    </Experiment>
  )
}

emitter.addPlayListener(function (experimentName: string, variantName: string) {
  Mixpanel.register({ [experimentName]: variantName })
})

export default ABtestTitle
