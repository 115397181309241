import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Mixpanel } from '../mixPanel/mixPanel'

interface TabProps {
  title: string
  subTitle: string
  content: any
  video?: string
  route: string
  img?: string
}

interface TabsProps {
  tabs: TabProps[]
}

const Tabs: React.FC<TabsProps> = ({ tabs }) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const navigate = useNavigate()
  return (
    <div className="flex container flex-col  items-center mx-auto lg:rounded-xl glass-effect bg-white bg-opacity-20 py-12 px-6 my-12 mb-32">
      <div className="flex w-full ">
        {tabs.map((tab, index) => (
          <div
            key={tab.title}
            className={`p-4 py-6 flex-grow text-center cursor-pointer text-sm lg:text-2xl relative ${
              activeIndex === index ? 'font-bold' : 'border-b border-gray-500'
            }`}
            onClick={() => {
              setActiveIndex(index)
              Mixpanel.track('User switched tab in home page', {
                tab: tab.title
              })
            }}
          >
            {tab.title}

            {activeIndex === index && 
            
            <div className='h-2 right-0 -bottom-1 rounded-full glass-effect bg-tuto-primary bg-opacity-60 absolute w-full'>

            </div>
            }
          </div>
        ))}
      </div>
      <div className="pt-12">
        <div className="flex flex-col gap-4 lg:flex-row lg:gap-x-4">
          <div className="lg:text-lg lg:w-1/2 flex  gap-4 flex-col justify-center">
            <h1 className='font-extrabold text-2xl'>
              {tabs[activeIndex].subTitle}
            </h1>
            {tabs[activeIndex].content.map((p:string, index:number) => (
              <p key={index}>{p}</p>
            ))}
            <div className="mt-4">
              <button onClick={() => {
                Mixpanel.track('User clicked on see more information', {tab:tabs[activeIndex].title })
                navigate(`/record/${tabs[activeIndex].route}`)
                }} className="border border-white rounded-lg p-2 hover:bg-white hover:text-tuto-fourth transition duration-300">
                See More Information
              </button>
            </div>
          </div>
          <div className="text-lg lg:w-1/2 p-2">
            {tabs[activeIndex].video ? 
            
            <video
              className="video min-w-full rounded-2xl"
              controls
              src={
                tabs[activeIndex].video
              }
              // poster={'https://assets.torecord.it/Web 1280 – 3.png'}
            /> : <img className='rounded-2xl' src={tabs[activeIndex].img}/> 
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default Tabs
