import React, { useState, useEffect, useRef } from 'react'
import SVG from '../components/SVG'
import { Mixpanel } from '../mixPanel/mixPanel'

interface TabProps {
  title: string
  content: React.ReactNode
  video: string
  img: string
  sideImg: boolean
  icon: string
  specialClass: string
}

interface TabsProps {
  tabs: TabProps[]
}

const ScrollTabs: React.FC<TabsProps> = ({ tabs }) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const tabsRef = useRef<HTMLDivElement>(null)
  const leftRef = useRef<HTMLUListElement>(null)
  const [fixed, setFixed] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      tabs.forEach((tab, index) => {
        const element = document.getElementById(tab.title)
        if (!element) return
        const y = element.getBoundingClientRect().top + window.pageYOffset
        const isActive = y >= window.scrollY - 200 && y <= window.scrollY + 200
        if (isActive && index !== activeTabIndex) {
          setActiveTabIndex(index)
        }
      })
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [activeTabIndex, tabs])

  useEffect(() => {
    const fixSideBar = () => {
      if (!tabsRef.current) return
      else {
        if (window.scrollY > tabsRef.current.offsetTop) {
          setFixed(true)
        } else {
          setFixed(false)
        }
        // if(tabsRef.current.offsetHeight < window.scrollY) {
        //   setFixed(false)
        // }
      }
    }

    window.addEventListener('scroll', fixSideBar)

    return () => {
      window.removeEventListener('scroll', fixSideBar)
    }
  }, [tabsRef])

  

  return (
    <div
      ref={tabsRef}
      className="flex relative justify-center gap-2 min-h-screen w-full"
    >
      <ul
        ref={leftRef}
        className={`hidden lg:flex z-10 flex-col items-start  gap-2 ${
          fixed
            ? 'fixed tabs-align-fixed top-44 '
            : 'absolute tabs-align-absolute top-0'
        }  left-12 `}
      >
        {tabs.map((tab, index) => (
          <li
            key={tab.title}
            className={`flex items-start max-w-44 rounded cursor-pointer glass-effect bg-white bg-opacity-20 p-2 rounded-md' ${
              index === activeTabIndex ? 'bg-opacity-70' : ''
            }`}
            onClick={() => {
              Mixpanel.track(`User clicked on tab`, {tab: tab.title})
              const element = document.getElementById(tab.title)
              if (element) {
                const y =
                  element.getBoundingClientRect().top + window.pageYOffset - 100
                window.scrollTo({ top: y, behavior: 'smooth' })
              }
            }}
          >
            {tab.icon !== '' && (
              <span className="mr-2">
                <SVG svgCode={tab.icon} />
              </span>
            )}
            {tab.title}
          </li>
        ))}
      </ul>
      <div className="px-4 px-88">
        {tabs.map((tab: any) => (
          <div key={tab.title} id={tab.title} className="mb-4 lg:mb-16">
            <h1 className="text-3xl font-semibold text-tuto-fourth mb-8">
              {tab.title}
            </h1>
            <div
              className={`flex gap-4 ${
                tab.sideImg ? 'flex-col lg:flex-row' : 'flex-col'
              }`}
            >
              <div className="xl:w-1/2">{tab.content}</div>
              <div className={`${tab.sideImg ? 'w-1/2' : 'lg:mt-20'}`}>
                <img
                  className={`w-half relative lg:bottom-16 ${tab.specialClass}`}
                  src={tab.img}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default ScrollTabs
