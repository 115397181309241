import React from 'react'
import { Mixpanel } from '../mixPanel/mixPanel'

const Experiment = require('react-ab-test/lib/Experiment')
const Variant = require('react-ab-test/lib/Variant')
const emitter = require('react-ab-test/lib/emitter')

emitter.defineVariants('Hero-P', ['CX', 'AI'], [50, 50])

const ABtestP = () => {
  return (
    <Experiment name="Hero-P">
      <Variant name="CX">
        <p className="mt-12 relative top-4 text-center text-base sm:mt-5 sm:text-xl md:max-w-3xl lg:text-lg xl:text-xl">
        AI-powered observability layer that monitors and automates the resolution process, to provide a profound understanding of what went wrong and what could go wrong in a way that no human could
        </p>
      </Variant>
      <Variant name="AI">
        <p className="mt-12 relative top-4 text-center text-base sm:mt-5 sm:text-xl md:max-w-3xl lg:text-lg xl:text-xl">
        AI-powered observability layer that monitors and automates the resolution process, to provide a profound understanding of what went wrong and what could go wrong in a way that no human could
        </p>
      </Variant>
    </Experiment>
  )
}

emitter.addPlayListener(function(experimentName:string, variantName:string){
  Mixpanel.register({[experimentName]:variantName});
}); 

export default ABtestP
