import {
  ArrowRightIcon,
  ArrowUpIcon,
  ChevronUpIcon
} from '@heroicons/react/outline';
import { Mixpanel } from '../mixPanel/mixPanel';
import Form from './form';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import linkedin from '../assets/Icon-awesome-linkedin.svg';
import slack from '../assets/slack.svg';

const navigation = [
  {
    name: 'How It Works',
    isLink: true,
    href: '#video',
    internalLink: true,
    path: '/#video'
  },
  {
    name: 'Webinars',
    isLink: false,
    href: `/resources`,
    internalLink: true,
    path: '/resources'
  },
  // {
  //   name: 'Playground',
  //   isLink: false,
  //   href: '',
  //   internalLink: true,
  //   path: '/resources'
  // },
  {
    name: 'Community',
    isLink: true,
    href: `https://join.slack.com/t/record-community/shared_invite/zt-ysvjbuxw-oD0qzYWu3HaDFYrZNAgOqQ`,
    internalLink: false,
    path: ''
  },
  {
    name: 'Terms of Use',
    isLink: false,
    href: `/terms`,
    internalLink: true,
    path: '/terms'
  },
  {
    name: 'Privacy Policy',
    isLink: false,
    href: `/privacy-policy`,
    internalLink: true,
    path: '/privacy-policy'
  },
  {
    name: 'Docs',
    isLink: false,
    href: `/docs`,
    internalLink: true,
    path: '/docs'
  },
  {
    name: '✡ Remember Yuval',
    isLink: true,
    href: `https://rememberyuval.co.il/`,
    internalLink: false,
    path: ''
  }
];

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div
      className="px-4 pb-16 py-10 sm:px-14 flex flex-col items-start justify-center text-normal-black bg-tuto-fourth relative z-50"
      id="join"
    >
      <button
        onClick={scrollToTop}
        className="bg-white flex items-center p-2 absolute right-28 top-10"
      >
        <ChevronUpIcon className="w-6 h-6" />
      </button>
      <div className="mb-12">
        <img
          className="h-12 w-auto"
          src="https://assets.torecord.it/logowhite.png"
        />
      </div>
      <div className="flex flex-col md:flex-row w-full">
        <div className="flex-grow mb-4 md:mb-0">
          <h1 className="text-3xl text-white mb-8">Join Our Newsletter</h1>
          <Form formType="newsletter" />
        </div>
        <div className="flex-grow flex justify-between p-2 md:p-8">
          <div className="flex flex-col">
            {navigation.map((item) =>
              item.internalLink ? (
                item.path.includes('#') ? (
                  <HashLink
                    className="font-light text-white hover:text-gray-300"
                    key={item.name}
                    to={item.path}
                    onClick={() => {
                      console.log('clicked on hash link');
                      Mixpanel.track(`Clicked on ${item.name}`, {
                        location: 'footer'
                      });
                      // if (item.name === 'See it in action') {
                      //   setOpen(true)
                      // }
                    }}
                  >
                    {item.name}
                  </HashLink>
                ) : (
                  <Link
                    key={item.name}
                    to={item.path}
                    className="font-light text-white hover:text-gray-300"
                    onClick={() => {
                      Mixpanel.track(`Clicked on ${item.name}`, {
                        locatoin: 'footer'
                      });
                      window.scrollTo({
                        top: 0,
                        behavior: 'smooth'
                      });
                      // if (item.name === 'See it in action') {
                      //   setOpen(true)
                      // }
                    }}
                  >
                    {item.name}
                  </Link>
                )
              ) : (
                <a
                  key={item.name}
                  href={(item.isLink && item.href) || '#'}
                  className="font-light text-white hover:text-gray-300"
                  onClick={() => {
                    Mixpanel.track(`Clicked on ${item.name}`, {
                      location: 'footer'
                    });
                    // if (item.name === 'See it in action') {
                    //   setOpen(true)
                    // }
                  }}
                >
                  {item.name}
                </a>
              )
            )}
          </div>
          <div className="flex items-end justify-center">
            <div className="flex items-center">
              <a
                href="https://www.linkedin.com/company/tutoit/"
                target={'_blank'}
                className="text-white hover:scale-110 transition duration-200"
              >
                <img src={linkedin} />
              </a>
              <a
                href="https://record-community.slack.com/ssb/redirect"
                target={'_blank'}
                className="text-white h-16 w-16 hover:scale-110 transition duration-200"
              >
                <img src={slack} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
