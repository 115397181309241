import { FC, Fragment, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useForm, SubmitHandler } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import Layout from '../layouts/Layout'
import Modal from '../components/Modal'
import { Link } from 'react-router-dom'
import { Mixpanel } from '../mixPanel/mixPanel'
import Badges from '../components/Badges'

interface IFormInputs {
  first_name: string
  last_name: string
  company_name: string
  phone: string
  email: string
  checkboxField: boolean
}

const schema = yup.object().shape({
  first_name: yup.string().required().min(2),
  last_name: yup.string().required().min(2),
  company_name: yup.string().required().min(2),
  phone: yup.string(),
  email: yup
    .string()
    .email()
    .matches(
      /(?=^((?!@gmail.com).)*$)(?=^((?!@yahoo.com).)*$)/,
      'email must be a work email'
    )
    .required(),
  checkboxField: yup
    .boolean()
    .oneOf(
      [true],
      'In order to proceed you need to accept privacy policy and term & conditions'
    )
})

const BookDemo = () => {
  const [loading, setLoading] = useState(false)
  const [thankYou, setThankYou] = useState(false)
  const [isOpen, setOpen] = useState(false)
  const recordModeHref = `${process.env.REACT_APP_DASHBOARD}login?playground=true`

  useEffect(() => {
    Mixpanel.track('Book a demo page viewd', {})
  },[])

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, touchedFields }
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    mode: 'all'
  })

  const formSubmitHandler: SubmitHandler<IFormInputs> = async (
    data: IFormInputs
  ) => {
    setLoading(true)
    const body = {
      name: data.first_name.concat(' ').concat(data.last_name),
      company_name: data.company_name,
      phone_number: data.phone,
      email: data.email,
      utm: 'demo request',
      is_book_demo: true
    }

    try {
      const newLead = await fetch(
        `${process.env.REACT_APP_API}/earlySignUp/addLead`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          method: 'POST',
          body: JSON.stringify(body)
        }
      )
   
      setThankYou(true)
    } catch (error) {
      console.log(error)
      setThankYou(true)
    }
  }

  return (
    <Layout withCircles={false}>
      <div className="relative  isolate py-24 px-6 sm:py-18  lg:px-8">
        <div className="purple_book_demo"></div>
        {!thankYou ? (
          <div className="mx-auto max-w-xl lg:max-w-6xl z-20">
            <div className=" flex flex-col gap-16 sm:gap-y-20 lg:flex-row">
              <div className=" max-w-lg lg:flex-none">
                <figure className="">
                  <h2 className="text-2xl lg:text-4xl font-extrabold ">
                    Want To Make An Impact On Your Support Process? Let’s Chat.
                  </h2>
                  <p className="mt-8 lg:mt-12 text-md lg:text-lg font-light lg:leading-8 ">
                    Talk with an expert to explore possibilities To excel in
                    your customer support. our team will be in touch within one
                    business day.
                  </p>

                  <div className="mt-28 hidden lg:block">
                    <h2 className="text-2xl mb-8 font-bold tracking-tight ">
                      Trusted by
                    </h2>
                    <div className="flex gap-4">
                      <img
                        className="w-36 h-auto"
                        src="https://assets.torecord.it/scrimmage-logo.png"
                        alt="Paypal"
                      />
                      <img
                        className="w-36 h-auto "
                        src="https://assets.torecord.it/group-gps-logo.png"
                        alt="Buildium"
                      />
                      <img
                        className="w-36 h-auto"
                        src="https://assets.torecord.it/dataloop-logo.png"
                        alt="Mirage"
                      />
                    </div>
                    <div className="flex gap-2  ">
                     <Badges />
                    </div>
                  </div>
                </figure>
                <div>
                  {/* <a href="https://www.producthunt.com/products/tuto/reviews?utm_source=badge-product_rating&utm_medium=badge&utm_souce=badge-tuto" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/product_rating.svg?product_id=467802&theme=light" alt="tuto - Create&#0032;an&#0032;in&#0045;app&#0032;measurable&#0032;tutorial&#0032;with&#0032;a&#0032;single&#0032;click | Product Hunt"  width="242" height="108" /></a>
              <a href="https://www.producthunt.com/posts/tuto?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-tuto" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=314206&theme=light&period=daily" alt="tuto - Create&#0032;an&#0032;in&#0045;app&#0032;measurable&#0032;tutorial&#0032;with&#0032;a&#0032;single&#0032;click | Product Hunt"  width="250" height="54" /></a> */}
                </div>
              </div>
              {/* <div className="isolate bg-white py-24 px-6 sm:py-32 lg:px-8"> */}
              <form
                onSubmit={handleSubmit(formSubmitHandler)}
                className="mx-auto max-w-xl  glass-effect bg-white bg-opacity-20 p-6 shadow-small rounded-xl z-10"
                id='book_demo'
              >
                <div className="grid grid-cols-2 gap-y-4 gap-x-8">
                  <div className="col-span-2 lg:col-span-1">
                    <label
                      htmlFor="first-name"
                      className="block text-xs lg:text-sm font-semibold lg:leading-6 "
                    >
                      FIRST NAME*
                    </label>
                    <div className="mt-2.5 relative">
                      <input
                        type="text"
                        {...register('first_name')}
                        id="first-name"
                        autoComplete="given-name"
                        className="block w-full text-tuto-fourth rounded-md border-0 py-2 px-3.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                      {errors.first_name && (
                        <span className="absolute text-xs text-red-500 lg:-bottom-4">
                          {errors.first_name.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-span-2 lg:col-span-1">
                    <label
                      htmlFor="last-name"
                      className="block text-xs lg:text-sm font-semibold lg:leading-6 "
                    >
                      LAST NAME*
                    </label>
                    <div className="mt-2.5 relative">
                      <input
                        type="text"
                        {...register('last_name')}
                        id="last-name"
                        autoComplete="family-name"
                        className="block w-full text-tuto-fourth rounded-md border-0 py-2 px-3.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                      {errors.last_name && (
                        <span className="absolute text-xs text-red-500 lg:-bottom-4">
                          {errors.last_name.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-span-2 relative">
                    <label
                      htmlFor="company"
                      className="block text-xs lg:text-sm font-semibold lg:leading-6 "
                    >
                      BUSSINES EMAIL ADRESS*
                    </label>
                    <div className="mt-2.5">
                      <input
                        type="text"
                        {...register('email')}
                        id="company"
                        autoComplete="organization"
                        className="block w-full text-tuto-fourth rounded-md border-0 py-2 px-3.5  shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                      {errors.email && (
                        <span className="absolute text-xs text-red-500 -bottom-4">
                          {errors.email.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-span-2 relative">
                    <label
                      htmlFor="email"
                      className="block text-xs lg:text-sm font-semibold lg:leading-6 "
                    >
                      COMPANY NAME*
                    </label>
                    <div className="mt-2.5">
                      <input
                        type="text"
                        {...register('company_name')}
                        id="email"
                        autoComplete="company"
                        className="block w-full text-tuto-fourth rounded-md border-0 py-2 px-3.5  shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                      {errors.company_name && (
                        <span className="absolute text-xs text-red-500 -bottom-4">
                          {errors.company_name.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-span-2 relative">
                    <label
                      htmlFor="phone-number"
                      className="block text-xs lg:text-sm font-semibold lg:leading-6"
                    >
                      PHONE NUMBER
                    </label>
                    <div className="relative mt-2.5">
                      <input
                        type="tel"
                        {...register('phone')}
                        id="phone-number"
                        autoComplete="tel"
                        className="block w-full text-tuto-fourth rounded-md border-0 py-2 px-3.5  shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                </div>
                <div className="flex  items-start gap-2 relative mt-4">
                  <div className="lg:pt-1/2">
                    <input
                      {...register('checkboxField')}
                      aria-describedby="comments-description"
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    />
                  </div>
                  <p className="mb-4 text-xs lg:text-sm lg:leading-6 ">
                    By checking this box, you agree to receive promotional email
                    communications about Record’s products, services, or events.
                    Your data will be processed in accordance with our{' '}
                    <a
                      href="https://torecord.it/security"
                      target={'blank'}
                      className="font-semibold text-tuto-fourth"
                    >
                      {' '}
                      Privacy Policy
                    </a>{' '}
                    and{' '}
                    <a
                      href="https://torecord.it/security"
                      target={'blank'}
                      className="font-semibold text-tuto-fourth"
                    >
                      Terms and Conditions
                    </a>
                    .You may opt out of receiving promotional communications at
                    any time.
                  </p>
                  {errors.checkboxField && (
                    <span className="absolute text-xs text-red-500 -bottom-3 lg:-bottom-2">
                      {errors.checkboxField.message}
                    </span>
                  )}
                </div>
                <div className="mt-4">
                  <button
                    type="submit"
                    className="button w-full text-center py-6 text-xl font-semibold justify-center"
                  >
                    Talk With An Expert
                  </button>
                </div>
              </form>
            </div>
            <div className="mt-8 visible lg:hidden">
              <h2 className="text-2xl mb-4 font-bold tracking-tight text-tuto-fourth">
                Trusted by
              </h2>
              <div className="grid grid-cols-3 items-center">
                <img
                  className="h-4  "
                  src="https://assets.torecord.it/paypal.png"
                  alt="Paypal"
                />
                <img
                  className="h-6 "
                  src="https://assets.torecord.it/buildium.png"
                  alt="Buildium"
                />
                <img
                  className="h-6  anodot"
                  src="https://assets.torecord.it/dataloop.png"
                  alt="Mirage"
                />
              </div>
              <div className="grid grid-cols-4 gap-4 mt-10 items-center">
                <img
                  className="h-12 col-span-2 "
                  src="https://assets.torecord.it/producthunt1.png"
                  alt="Paypal"
                />
                <img
                  className="h-12 col-span-2 "
                  src="https://assets.torecord.it/producthunt2.png"
                  alt="Buildium"
                />
              </div>
            </div>
            <div className="purple-leadgen"></div>
          </div>
        ) : (
          <div className="flex  items-center justify-center min-h-70v">
            <div className="flex flex-col text-center items-center gap-4">
              <h2 className="text-4xl font-extrabold  text-tuto-fourth">
                Thank You.
              </h2>
              <p className="text-lg font-light leading-8 text-tuto-fourth">
                We will reach out in a business day time
              </p>
              <div className="flex flex-col lg:flex-row gap-4">
                <button
                  onClick={() => {
                    Mixpanel.track('User clicked on See Live Demo', {
                      location: 'Thank you page'
                    })
                    setOpen(true)
                  }}
                  className="hidden lg:block border border-tuto-fourth rounded-lg px-4 py-2 hover:text-white hover:bg-tuto-fourth transition duration-300"
                >
                  See Live Demo
                </button>
                <Link
                  onClick={() =>
                    Mixpanel.track('User clicked on Home Page', {
                      location: 'Thank you page'
                    })
                  }
                  to="/"
                  className="border border-tuto-fourth rounded-lg px-4 py-2 hover:text-white hover:bg-tuto-fourth transition duration-300"
                >
                  Home Page
                </Link>
                <Link
                  onClick={() =>
                    Mixpanel.track('User clicked on Docs', {
                      location: 'Thank you page'
                    })
                  }
                  to="/docs"
                  className="border border-tuto-fourth rounded-lg px-4 py-2 hover:text-white hover:bg-tuto-fourth transition duration-300"
                >
                  Explore Our Docs
                </Link>
              </div>
            </div>
          </div>
        )}
      </div>
      <Modal
        isOpen={isOpen}
        setOpen={setOpen}
        isVideo={false}
        href={recordModeHref}
      />
    </Layout>
  )
}

export default BookDemo
