import { useState, useEffect, FC } from 'react'

interface ColoredTitleProps {
  title: string,
  wordsNumberToEmphasize: number[]

}

const ColoredTitle: React.FC<ColoredTitleProps> = ({ title, wordsNumberToEmphasize }) => {
  const words = title.split(' ');

  const emphasizedWords = words.map((word, index) => {
    const baseClasses =
    "text-3xl font-extrabold font-montserrat sm:mt-5 sm:text-6xl lg:mt-6  capitalize"

    const extraClass = wordsNumberToEmphasize.includes(index) ? '' : '';

    return (
      <span key={index} className={`${baseClasses} ${extraClass}`}>
        {word}
        {index !== words.length - 1 && ' '}
      </span>
    );
  });

  return (
    <div className="mb-6 top-20 right-0 left-0 text-center">
      {emphasizedWords}
    </div>
  ) 
}

export default ColoredTitle
