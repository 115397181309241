import { useEffect, useState } from 'react'
import Layout from '../layouts/Layout'
import { Mixpanel } from '../mixPanel/mixPanel'
import './pages.css'
import Modal from '../components/Modal'
import SVG from '../components/SVG'
import { Link, useParams } from 'react-router-dom'
import { integrations } from '../content/integrations'
import { PlusIcon } from '@heroicons/react/outline'

const icons = [
  `<svg xmlns="http://www.w3.org/2000/svg" class='h-10 w-10' width="27" height="33" viewBox="0 0 27 33">
    <g id="Icon_feather-file-text" data-name="Icon feather-file-text" transform="translate(-4.5 -1.5)">
      <path id="Path_427" data-name="Path 427" d="M21,3H9A3,3,0,0,0,6,6V30a3,3,0,0,0,3,3H27a3,3,0,0,0,3-3V12Z" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
      <path id="Path_428" data-name="Path 428" d="M21,3v9h9" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
      <path id="Path_429" data-name="Path 429" d="M24,19.5H12" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
      <path id="Path_430" data-name="Path 430" d="M24,25.5H12" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
      <path id="Path_431" data-name="Path 431" d="M15,13.5H12" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
    </g>
  </svg>`,
  `<svg xmlns="http://www.w3.org/2000/svg" class='h-10 w-10' width="37.999" height="26.263" viewBox="0 0 37.999 26.263">
    <path id="Icon_awesome-terminal" data-name="Icon awesome-terminal" d="M15.317,16.619,3.778,28.158a1.425,1.425,0,0,1-2.015,0L.417,26.812a1.425,1.425,0,0,1,0-2.013L9.56,15.612.415,6.424a1.425,1.425,0,0,1,0-2.013L1.763,3.065a1.425,1.425,0,0,1,2.015,0L15.317,14.6A1.425,1.425,0,0,1,15.317,16.619ZM38,27.486v-1.9a1.425,1.425,0,0,0-1.425-1.425H18.525A1.425,1.425,0,0,0,17.1,25.586v1.9a1.425,1.425,0,0,0,1.425,1.425h18.05A1.425,1.425,0,0,0,38,27.486Z" transform="translate(0 -2.648)"/>
  </svg>`
]

const Zendesk = () => {
  const [fromZendesk, setFromZendesk] = useState(true)
  const [videoOpen, setVideoOpen] = useState(false)
  const params = useParams()
  const [integration, setIntegration] = useState({
    name: '',
    description: '',
    img: '',
    video: '',
    bullets: [],
    connectUrl: '',
    icon: ''
  })

  useEffect(() => {
    const foundIntegration: any = integrations.filter(
      (found: any) => found.name.toLowerCase() === params.id
    )
    if (foundIntegration && foundIntegration.length > 0) {
      Mixpanel.register({ Page: foundIntegration[0].name })
      Mixpanel.track(`${foundIntegration[0].name} Page View`, {})
      setIntegration({
        name: foundIntegration[0].name,
        description: foundIntegration[0].description,
        img: foundIntegration[0].img,
        video: foundIntegration[0].video,
        bullets: foundIntegration[0].bullets,
        connectUrl: foundIntegration[0].connectUrl,
        icon: foundIntegration[0].icon
      })
    }
    if(window.scrollY > 0) {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }, [params])

  return (
    <Layout withCircles={true}>
      <div className="flex flex-col gap-4 px-6 lg:px-0 items-start lg:items-center justify-center my-12 mt-24 relative">
        <div>
          <SVG svgCode={integration.icon} />
        </div>
        <h1 className="mb-6  text-normal-black lg:text-center text-5xl font-bold font-montserrat sm:mt-5 sm:text-6xl lg:mt-4 xl:text-6xl leading-normal">
          {integration.name} Integration
        </h1>
        <p className="mb-6 lg:text-center text-normal-black text-base sm:text-xl md:max-w-2xl lg:text-lg xl:text-xl">
          {integration.description}
        </p>
        <a
          onClick={() => {
            Mixpanel.track('Clicked on Start For Free', {
              Location: integration.name
            })
          }}
          href={`${process.env.REACT_APP_DASHBOARD}signup`}
          target="_blank"
          className="button "
        >
          {/* <ArrowRightIcon className="h-5 w-5 mr-2" /> */}
          Connect Your {integration.name}
        </a>
        {/* <div className="text-gray-500 mt-4 font-light">
          Free forever | no credit card needed
        </div> */}
      </div>
      {/* <div className="container px-6 lg:px-20 mt-24 mx-auto flex flex-wrap items-center justify-around opacity-50">
        <img
          className="h-10 opacity-70 mb-8 md:mb-0"
          src="https://assets.torecord.it/buildium.png"
          alt="Tuple"
        />
        <img
          className="h-10 opacity-70 anodot mb-8 md:mb-0"
          src="https://assets.torecord.it/dataloop.png"
          alt="Mirage"
        />
        <img
          className="h-6 opacity-70"
          src="https://assets.torecord.it/paypal.png"
          alt="StaticKit"
        />
        <img
          className="h-5 opacity-70 transform"
          src="https://assets.torecord.it/pingboard.png"
          alt="Transistor"
        />
        <img
          className="h-8 opacity-70"
          src="https://assets.torecord.it/syte.png"
          alt="Workcation"
        />
      </div> */}

      <div
        className="my-16 mt-40 w-full relative"
        onClick={() => {
          setVideoOpen(true)
          Mixpanel.track('User clicked on video image', {})
        }}
      >
        <div className="absolute lg:text-4xl font-bold top-1/2 left-1/2 -translate-x-1/2 cursor-pointer">
          How it works?
        </div>
        <img className="cursor-pointer w-full" src={integration.img} />
        <Modal
          isOpen={videoOpen}
          setOpen={setVideoOpen}
          isVideo={true}
          videoSrc={integration.video}
        />
      </div>
      {integration.bullets &&
        integration.bullets.map((bullet: any, index: number) => (
          <div
            key={bullet.title}
            className="flex flex-col lg:flex-row relative px-8 lg:px-0 container mx-auto mb-20 lg:gap-12 lg:items-center"
          >
            <div className="flex lg:pr-20 mb-16 md:mb-20 flex-grow lg:w-1/2">
              <div className="flex flex-col">
                <div className="flex lg:mb-12">
                  <div className="bg-tuto-fourth text-white rounded-md mb-4  py-2 px-4">
                    {bullet.badge}
                  </div>
                </div>
                <div>
                  <h1 className="text-tuto-fourth text-4xl lg:text-4xl font-bold mb-4">
                    {bullet.title}
                  </h1>
                  <p className="mt-4 text-base sm:text-lg text-gray-500">
                    {bullet.text}
                  </p>
                </div>
              </div>
            </div>
            <div
              className={`video-bg-${
                index + 1
              } rounded-xl py-4 px-4 lg:px-10 flex-grow lg:w-1/2`}
            >
              <img
                src={bullet.img}
                className="shadow-small rounded-xl relative  top-12"
              />
            </div>
          </div>
        ))}
      <div className="container px-8 lg:px-0 mx-auto my-12">
        <h1 className="text-tuto-fourth text-4xl mb-12 lg:text-5xl font-bold ">
          Resources
        </h1>
        <div className="grid lg:grid-cols-3 gap-6">
          <Link
            to="/docs"
            className="text-tuto-fourth px-8 py-16 bg-gray-50 rounded-lg flex flex-col gap-4 items-center hover:bg-gray-200 transition duration-300"
          >
            <SVG svgCode={icons[0]} />
            <p className="font-semibold text-2xl">Documetation</p>
          </Link>
          <Link
            to="/resources/videos"
            className="text-tuto-fourth px-8 py-16 bg-gray-50 rounded-xl flex flex-col gap-4 items-center hover:bg-gray-200 transition duration-300"
          >
            <SVG svgCode={icons[1]} />
            <p className="font-semibold text-2xl">How to videos</p>
          </Link>
          <a
            target={'_blank'}
            href={integration.connectUrl}
            className="text-tuto-fourth px-8 py-16 bg-gray-50 rounded-lg flex flex-col gap-4 items-center hover:bg-gray-200 transition duration-300"
          >
            <PlusIcon className="h-10 w-10" />
            <p className="font-semibold text-2xl">Connect</p>
          </a>
        </div>
      </div>
      {/* <div className="flex items-center justify-center lg:mt-20">
        <div
          className={`microphone-wrapper`}
          onClick={() => {
            setFromZendesk(!fromZendesk)
            fromZendesk
              ? Mixpanel.track('User toggeled to Start From Record', {})
              : Mixpanel.track('User toggeled to Start From Zendesk', {})
          }}
        >
          <div
            className={`absolute microphone ${fromZendesk ? 'on' : 'off'} `}
          ></div>
          <span
            className={`absolute text-sm right-6 ${
              fromZendesk ? 'text-tuto-fourth' : 'text-white'
            }`}
          >
            Start from Record
          </span>
          <span
            className={`absolute text-sm left-6 ${
              fromZendesk ? 'text-white' : 'text-tuto-fourth'
            }`}
          >
            Start from Zendesk
          </span>
        </div>
      </div> */}
      {/* {fromZendesk ? (
        <div className="container mx-auto lg:mt-32 feature">
          <div className="flex flex-col-reverse sm:grid lg:mx-auto lg:max-w-full lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
            <div className="px-8 max-w-xl mx-auto sm:px-6 lg:py-2 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-1">
              <div>
                <div></div>
                <div className="mt-6">
                  <h2 className="text-normal-black text-4xl sm:text-5xl font-extrabold tracking-tight font-montserrat">
                    Request and get records directly from Zendesk
                  </h2>
                  <p className="my-4 text-base sm:text-2xl text-gray-500">
                    Let your customer’s record issues with voice and with a
                    visual representation of the issue. The record, console log,
                    and network data will come directly into the agent’s
                    account. Let your customers record issues with voice and
                    with a visual representation of the issue. The record,
                    console log, and network data will come.
                  </p>
                  <div className="flex flex-col items-start">
                    <a
                      onClick={() => {
                        Mixpanel.track('Clicked on Start For Free', {
                          Location: 'Zendesk Tab'
                        })
                      }}
                      href={`${process.env.REACT_APP_DASHBOARD}signup`}
                      target="_blank"
                      className="inline-flex mb-4 items-center px-4 py-2 border border-black text-base font-medium rounded-md  bg-tuto-secondary hover:bg-tuto-secondary-hover"
                    >
                      <ArrowRightIcon className="h-5 w-5 mr-2" />
                      Start For Free
                    </a>
                    <a
                      onClick={() => {
                        Mixpanel.track('Clicked on documentation', {
                          Location: 'Zendesk Tab'
                        })
                      }}
                      href={`${process.env.REACT_APP_DASHBOARD}signup`}
                      target="_blank"
                      className="px-4 py-2 text-base text-tuto-primary font-medium rounded-md  bg-secondary hover:bg-tuto-primary hover:text-white transition duration-300"
                    >
                      Documentation
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-start-2">
              <div className="pr-4 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                <img
                  className="w-full  lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
                  src="https://assets.torecord.it/ZendeskFrom.png"
                  alt="Customer profile user interface"
                  // style={{ maxHeight: '97%' }}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="container mx-auto lg:mt-32 feature">
          <div className="flex flex-col-reverse sm:grid lg:mx-auto lg:max-w-full lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
            <div className="px-8 max-w-xl mx-auto sm:px-6 lg:py-2 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
              <div>
                <div></div>
                <div className="mt-6">
                  <h2 className="text-normal-black text-4xl sm:text-5xl font-extrabold tracking-tight font-montserrat">
                    Create a Ticket using Record.
                  </h2>
                  <p className="my-4 text-base sm:text-2xl text-gray-500">
                    Now your customers can start their support journey starting
                    from recording the issue. This was a highly requested
                    feature. Use a special sharable link and button to open the
                    Record experience. Then we will open a new ticket starting
                    from the recording and the user description of the issue.
                  </p>
                  <div className="flex flex-col items-start">
                    <a
                      onClick={() => {
                        Mixpanel.track('Clicked on Start For Free', {
                          Location: 'Record Tab'
                        })
                      }}
                      href={`${process.env.REACT_APP_DASHBOARD}signup`}
                      target="_blank"
                      className="inline-flex mb-4 items-center px-4 py-2 border border-black text-base font-medium rounded-md  bg-tuto-secondary hover:bg-tuto-secondary-hover"
                    >
                      <ArrowRightIcon className="h-5 w-5 mr-2" />
                      Start For Free
                    </a>
                    <a
                      onClick={() => {
                        Mixpanel.track('Clicked on documentation', {
                          Location: 'Record Tab'
                        })
                      }}
                      href={`${process.env.REACT_APP_DASHBOARD}signup`}
                      target="_blank"
                      className="px-4 py-2 text-base text-tuto-primary font-medium rounded-md  bg-secondary hover:bg-tuto-primary hover:text-white transition duration-300"
                    >
                      Documentation
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
              <div className="pr-4 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                <img
                  className="w-full  lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
                  src="https://assets.torecord.it/ZendeskStartfromRecord.png"
                  alt="Customer profile user interface"
                  // style={{ maxHeight: '95%' }}
                />
              </div>
            </div>
          </div>
        </div>
      )} */}
      {/* <div className="px-4 lg:px-0 container mx-auto">
        <FooterMenu />
      </div> */}
    </Layout>
  )
}

export default Zendesk
