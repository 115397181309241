import React, { useRef, useEffect, FC } from 'react';
import './Images.css'

interface ImageComponentProps {
  images?: any;
  children?: React.ReactNode;
  additionalClass?: string
}

const ImageComponent: FC<ImageComponentProps> = ({images, children, additionalClass}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && containerRef.current) {
        containerRef.current.classList.add('in-view');
      } else if (containerRef.current) {
        containerRef.current.classList.remove('in-view');
      }
    },  {
      rootMargin: '-250px 0px'
    });

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    // Cleanup
    return () => observer.disconnect();
  }, []);

  return (
    <div className={`image-container ${additionalClass}`}ref={containerRef}>
      {images && images.map((image: any) => (
        <img src={image.url} alt="image1" className={image.class} />

        ))}
        {children}
    </div>
  );
}

export default ImageComponent