import { useState, useEffect } from 'react'
import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { ArrowRightIcon, XIcon, MenuIcon } from '@heroicons/react/outline'
import { Mixpanel } from '../mixPanel/mixPanel'
import Modal from './Modal'
import { Link, NavLink } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import { navigation } from '../layouts/navigation'
import { classNames } from '../utils/functions'
import FlyoutMenu from './FlyoutMenu'
import InternalMenu from './InternalMenu'

const Header = () => {
  const [navScroled, setNavScroled] = useState(false)
  const [isOpen, setOpen] = useState(false)
  const [internalMenu, setInternalMenu] = useState(false)
  const [subMenuToShow, setSubMenuToShow] = useState('Product')
  const recordModeHref = `${process.env.REACT_APP_DASHBOARD}login?playground=true`
  const changeBackground = () => {
    if (window.scrollY >= 90) {
      setNavScroled(true)
    } else {
      setNavScroled(false)
    }
  }

  useEffect(() => {
    changeBackground()
    window.addEventListener('scroll', changeBackground)
  })

  return (
    <>
      <Popover
        as="header"
        className={`sticky  top-0 z-40 transition duration-300 ${
          navScroled ? 'shadow bg-dark-bg' : ''
        }`}
      >
        {({ close }) => (
          <>
            <div className="container mx-auto py-2 sm:px-6 md:px-0">
              <nav
                className="relative flex items-center justify-between sm:h-10 md:justify-center"
                aria-label="Global"
              >
                <div className="flex items-center flex-1 md:absolute md:inset-y-0 md:left-0">
                  <div className="flex items-center justify-between w-full md:w-auto">
                    <Link 
                      className="ml-2 md:ml-0" 
                      to="/"
                      onClick={() => {
                        Mixpanel.track(
                          `Clicked on Record Logo`, {}
                        )
                        close()
                      }}>
                      <span className="sr-only">record</span>
                      <img
                        className="h-8 w-auto sm:h-10"
                        src="https://assets.torecord.it/recordlogowhite.png"
                        alt="record"
                      />
                    </Link>
                    <div className="mr-2 md:mr-0 flex items-center md:hidden">
                      <Popover.Button className="glass-effect bg-white bg-opacity-20 rounded-md p-2 inline-flex items-center justify-center text-white">
                        <span className="sr-only">Open main menu</span>
                        <MenuIcon className="h-6 w-6" aria-hidden="true" />
                      </Popover.Button>
                    </div>
                  </div>
                </div>
                <div className="hidden md:flex md:space-x-10">
                  {navigation.map((item) =>
                    item.showInHeader ? (
                      item.internalLink ? (
                        item.children ? (
                          <FlyoutMenu
                            key={item.name}
                            name={item.name}
                            children={item.children}
                            twoSides={item.twoSides}
                            withMedia={item.withMedia}
                            withIntegration={item.withIntegration}
                          />
                        ) : item.path.includes('#') ? (
                          <HashLink
                            className="font-light hover:text-gray-900"
                            key={item.name}
                            to={item.path}
                            onClick={() => {
                              Mixpanel.track(
                                `Clicked on ${item.name}`,
                                {"locatoin": "header"}
                              )
                              if (item.name === 'See it in action') {
                                setOpen(true)
                              }
                              close()
                            }}
                          >
                            {item.name}
                          </HashLink>
                        ) : (
                          <NavLink
                            key={item.name}
                            to={item.path}
                            className={({ isActive }) =>
                              classNames(
                                isActive
                                  ? 'font-semibold text-tuto-primary'
                                  : 'font-light hover:text-tuto-primary'
                              )
                            }
                            onClick={() => {
                              Mixpanel.track(
                                `Clicked on ${item.name}`,
                                {"locatoin": "header"}
                              )
                              if (item.name === 'See it in action') {
                                setOpen(true)
                              }
                              close()
                            }}
                          >
                            {item.name}
                          </NavLink>
                        )
                      ) : (
                        <a
                          key={item.name}
                          href={item.path || '#'}
                          className="font-light hover:text-tuto-primary"
                          onClick={() => {
                            Mixpanel.track(
                              `Clicked on ${item.name}`,
                              {"locatoin": "header"}
                            )
                            if (item.name === 'See it in action') {
                              setOpen(true)
                            }
                          }}
                        >
                          {item.name}
                        </a>
                      )
                    ) : null
                  )}
                </div>
                <div className="hidden md:absolute md:flex md:items-center md:justify-end md:inset-y-0 md:right-0 gap-4">
                <span className="inline-flex rounded-md">
                    <a
                      onClick={() => {
                        Mixpanel.track('Clicked on Login', {
                          "location": 'header'
                        })
                      }}
                      href={`${process.env.REACT_APP_DASHBOARD}login`}
                      target="_blank"
                      className="text-tuto-primary "
                    >
                      
                      Login
                    </a>
                  </span>
                  <span className="inline-flex rounded-md shadow">
                    <a
                      onClick={() => {
                        Mixpanel.track('Clicked on Get Started', {
                          "location": 'header'
                        })
                      }}
                      href={`${process.env.REACT_APP_DASHBOARD}signup`}
                      target="_blank"
                      className="button font-bold large-button"
                    >
                      {/* <ArrowRightIcon className="h-5 w-5 mr-2" /> */}
                      Get Started
                    </a>
                  </span>
                </div>
              </nav>
            </div>

            <Transition
              as={Fragment}
              enter="duration-150 ease-out"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="duration-100 ease-in"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Popover.Panel
                focus
                className="absolute z-10 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
              >
                <div className="rounded-lg shadow-md glass-effect bg-white bg-opacity-20 ring-1 ring-black ring-opacity-5 overflow-hidden">
                  <div className="px-5 pt-4 flex items-center justify-between">
                    <div>
                      <Link to="/">
                        <img
                          className="h-8 w-auto"
                          src="https://assets.torecord.it/recordlogowhite.png"
                          alt=""
                        />
                      </Link>
                    </div>
                    <div className="-mr-2">
                      <Popover.Button className="glass-effect bg-white bg-opacity-20 rounded-md p-2 inline-flex items-center justify-center">
                        <span className="sr-only">Close menu</span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                      </Popover.Button>
                    </div>
                  </div>
                  <div className="px-2 pt-2 pb-3">
                    {internalMenu ? (
                      <InternalMenu
                        setInternalMenu={setInternalMenu}
                        subMenuToShow={subMenuToShow}
                      />
                    ) : (
                      navigation.map((item) =>
                        item.showInHeader ? (
                          item.internalLink ? (
                            item.path.includes('#') ? (
                              <HashLink
                                className="block px-3 py-2 rounded-md text-base font-medium  hover:text-gray-900 hover:bg-gray-50"
                                key={item.name}
                                to={item.path}
                                onClick={() => {
                                  console.log('clicked on hash link')
                                  Mixpanel.track(
                                    `Clicked on ${item.name}`,
                                    {"locatoin": "header"}
                                  )
                                  if (item.name === 'See it in action') {
                                    setOpen(true)
                                  }
                                  close()
                                }}
                              >
                                {item.name}
                              </HashLink>
                            ) : item.children ? (
                              <div
                                key={item.name}
                                className="block px-3 py-2 rounded-md text-base font-medium  hover:text-gray-900 hover:bg-gray-50"
                                onClick={() => {
                                  Mixpanel.track(
                                    `Clicked on ${item.name}`,
                                    {"locatoin": "header"}
                                  )
                                  if (item.name === 'Resources' || item.name === 'Product') {
                                    setSubMenuToShow(item.name)
                                    setInternalMenu(true)
                                  }
                                }}
                              >
                                {item.name}
                              </div>
                            ) : (
                              <Link
                                key={item.name}
                                to={item.path}
                                className="block px-3 py-2 rounded-md text-base font-medium  hover:text-gray-900 hover:bg-gray-50"
                                onClick={() => {
                                  Mixpanel.track(
                                    `Clicked on ${item.name}`,
                                    {"locatoin": "header"}
                                  )
                                  if (item.name === 'See it in action') {
                                    setOpen(true)
                                  }
                                  close()
                                }}
                              >
                                {item.name}
                              </Link>
                            )
                          ) : (
                            <a
                              key={item.name}
                              href={item.path || '#'}
                              className="block px-3 py-2 rounded-md text-base font-medium  hover:text-tuto-primary hover:bg-gray-50"
                              onClick={() => {
                                Mixpanel.track(
                                  `Clicked on ${item.name}`,
                                  {"locatoin": "header"}
                                )
                                if (item.name === 'See it in action') {
                                  setOpen(true)
                                }
                              }}
                            >
                              {item.name}
                            </a>
                          )
                        ) : null
                      )
                    )}
                  </div>
                  {!internalMenu && (
                    <a
                      href={`${process.env.REACT_APP_DASHBOARD}signup`}
                      className="block w-full px-5 py-3 text-center font-medium bg-tuto-primary text-white border border-white border-opacity-50 rounded-b-lg"
                    >
                      Get Started
                    </a>
                  )}
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
      <Modal
        isOpen={isOpen}
        setOpen={setOpen}
        isVideo={false}
        href={recordModeHref}
      />
    </>
  )
}

export default Header
