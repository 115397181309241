import { Link, useMatch, useLocation } from 'react-router-dom'
import { Mixpanel } from '../mixPanel/mixPanel'
import Resource from '../components/Resource'
import { useEffect, useState } from 'react'
import { resources } from '../content/webinars'

// interface ResourceTabProps {
//   resourceType: string
// }

const ResourceTab = () => {
  const [resourcesToShow, setResourcesToShow] = useState<any>([])
  const location = useLocation()
  useEffect(() => {
    if (location.pathname.includes('webinars')) {
      const resourcesFilter = resources.filter((resource) => resource.type === 'Webinar')
      setResourcesToShow(resourcesFilter)
    } else if (location.pathname.includes('videos')) {
      const resourcesFilter = resources.filter((resource) => resource.type === 'Video')
      setResourcesToShow(resourcesFilter)
    } else  {
      setResourcesToShow(resources)
    }

    if(window.scrollY > 0) {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
   
  }, [location])

  return (
    <div className="flex flex-col flex-wrap md:flex-row">
      {resourcesToShow.map((resource: any) => (
        <Link
          onClick={() =>
            Mixpanel.track('Clicked on resource', {
              type: resource.type,
              title: resource.title
            })
          }
          to={`/resources/${resource.type.toLowerCase()}s/${resource.slug}`}
          key={resource.slug}
        >
          <Resource
            title={resource.title}
            type={resource.type}
            imgSrc={resource.imgSrc}
          />
        </Link>
      ))}
    </div>
  )
}

export default ResourceTab
