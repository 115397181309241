import ABtestP from './ABtestP'
import ABtestTitle from './ABtest'
import { Mixpanel } from '../mixPanel/mixPanel'
import { ArrowRightIcon } from '@heroicons/react/solid'
import HeroVideo from './HeroVideo'

const Experiment = require('react-ab-test/lib/Experiment')
const Variant = require('react-ab-test/lib/Variant')
const emitter = require('react-ab-test/lib/emitter')

// emitter.defineVariants('Hero', ['No-Img'], [20, 80])


const ABtestHero = () => {
  return (
    <div className="flex items-start mt-10 md:mt-0 justify-center relative bg-transparent overflow-x-hidden">
      <main className="">
        <div className="pt-10 bg-transpaetnt sm:pt-16 lg:pt-0 lg:pb-14 lg:overflow-hidden">
          <div className="mx-auto container">
            <div className="">
              <div className="mx-auto max-w-md px-4 sm:max-w-6xl sm:px-6 sm:text-center lg:px-0 lg:text-left lg:flex lg:items-center">
                <div className="lg:py-20 flex flex-col items-center">
                  <ABtestTitle />
                  <ABtestP />
                  <div className="mt-32 md:mt-20 flex gap-4 items-center">
                    <a
                      href={`${process.env.REACT_APP_DASHBOARD}signup`}
                      target="_blank"
                      className="button large-button font-bold"
                      onClick={() => {
                        Mixpanel.track('Clicked on Get Started', {
                          Location: 'Hero'
                        })
                      }}
                    >
                      {/* <ArrowRightIcon className="h-5 w-5 mr-2" /> */}
                      Get Started
                    </a>
                    {/* <a
                      href={'/book-a-demo'}
                      className="trans-button large-button px-8"
                      onClick={() => {
                        Mixpanel.track('Clicked on Book A Demo', {
                          Location: 'Hero'
                        })
                      }}
                    >
                      Book A Demo
                    </a> */}
                    {/* <div className="text-gray-500 mt-4 font-light">
                          Free forever | no credit card needed
                        </div> */}
                  </div>
                  <HeroVideo />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}

// emitter.addPlayListener(function(experimentName:string, variantName:string){

//   Mixpanel.register({[experimentName]: variantName});
// });

export default ABtestHero
