import React, { useRef, useEffect } from 'react';

const HeroVideo: React.FC = () => {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
      const [entry] = entries;
      if (entry.isIntersecting) {
        console.log('intersecting')
        entry.target.classList.add('animatePerspective');
      } else {
        entry.target.classList.remove('animatePerspective');
      }
    }

    const observerOptions = {
      root: null,
      rootMargin: '-400px 0px',
      threshold: 0.1
    };

    const observer = new IntersectionObserver(handleIntersection, observerOptions);

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    // Cleanup
    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
      }
    }
  }, []);

  return (
    <div  className="flex justify-center items-center mt-0 2xl:mt-16 p-12 w-full video-container">
      <video ref={videoRef} poster='https://assets.torecord.it/record_meta_cover.png' controls className="mx-auto hero-video glass-effect bg-white bg-opacity-20 video-rotate hero-vid">
        <source src="https://assets.torecord.it/record_demo.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
}

export default HeroVideo;
