import { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { ArrowRightIcon } from '@heroicons/react/outline'
import { Mixpanel } from '../mixPanel/mixPanel'

interface IFormInputs {
  email: string
}

interface IFormProps {
  formType: string
}

const schema = yup.object().shape({
  email: yup.string().email().matches(/(?=^((?!@gmail.com).)*$)(?=^((?!@yahoo.com).)*$)/, 'email must be a work email').required()
})

const Form: React.FunctionComponent<IFormProps> = ({ formType }) => {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors, touchedFields }
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    mode: 'all'
  })

  const [loading, setLoading] = useState(false)
  const [buttonText, setButtonText] = useState('Join')
  const [formSent, setFormSent] = useState(false)

  useEffect(() => {
    if(formType === 'playground') {
      setButtonText('Send Invitation')
    }
    if(formType === 'newsletter') {
      setButtonText('Join')
    }
  }, [])

  const formSubmitHandler: SubmitHandler<IFormInputs> = async (
    data: IFormInputs
  ) => {
    const body = {
      email: data.email
    }
    setLoading(true)
    Mixpanel.track('Clicked on join newsletter', { location: 'footer', email: data.email })
    try {
      let res;
      if(formType === 'playground') {
        res = await fetch(
          `${process.env.REACT_APP_API}/earlySignUp/sendPlaygroundMobileMail`,
          {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify(body)
          }
        )
      }
      if(formType === 'newsletter') {

        res = await fetch(
         `${process.env.REACT_APP_API}/earlySignUp/newsletter`,
         {
           headers: {
             Accept: 'application/json',
             'Content-Type': 'application/json'
           },
           method: 'POST',
           body: JSON.stringify(body)
         }
       )
      }

      if(res) {

        const parsedRes = await res.json()
        if (parsedRes.success) {
          setButtonText('thanks!')
          setLoading(false)
          setFormSent(true)
        }
      }
    } catch (error) {
      setLoading(false)
      reset()
      setButtonText('thanks!')
    }
  }

  return (
    <form id={formType} className="sm:max-w-xl" onSubmit={handleSubmit(formSubmitHandler)}>
      {!formSent ? (
        <div className='relative'>
          <div className="flex  rounded">
            <div className="min-w-0 flex-1 relative">
              {/* <label
                htmlFor="email"
                className="absolute left-4 -top-5 bg-white px-1 rounded"
              >
                Signup for early access
              </label> */}
              <input
                {...register('email')}
                id="email"
                type="email"
                placeholder="Enter Your Work Email..."
                className="block bg-transparent border-0 border-b-2 border-white w-full  px-4 py-3 text-base text-gray-300 placeholder-gray-500 focus:ring-0"
              />
            </div>
            <div className="absolute right-0 sm:mt-0 sm:ml-3">
              <button
                type="submit"
                className="flex items-center w-full py-3 px-4 rounded-md shadow text-white hover:bg-white hover:text-tuto-fourth font-medium  focus:outline-none transition duration-300  "
              >
                {loading ? (
                  <span className="animate-spin h-5 w-5 border-r-2 rounded-full border-black"></span>
                ) : (
                  <ArrowRightIcon className="h-5 w-5" />
                )}
                {/* {buttonText} */}
              </button>
            </div>
          </div>
          <span className="text-red-500">{errors.email?.message}</span>
        </div>
      ) : (
        <div className="flex border rounded p-1 border-black">
          <div className="flex items-center w-full py-3 px-4 rounded-md shadow bg-tuto-secondary border border-black font-medium  focus:outline-none hover:bg-tuto-secondary-hover transition duration-300 ">
            <ArrowRightIcon className="h-5 w-5 mr-2" />
            Thanks, We Got Your Request!
          </div>
        </div>
      )}
    </form>
  )
}

export default Form
