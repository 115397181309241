import React from 'react'

const Badges = () => {
  return (
    <div className='flex flex-col md:flex-row mx-auto items-center justify-center mt-16 gap-4 opacity-50'>
      <a
        href="https://www.producthunt.com/posts/record-3-0?utm_source=badge-top-post-topic-badge&utm_medium=badge&utm_souce=badge-record&#0045;3&#0045;0"
        target="_blank"
        className='border border-white rounded-lg flex '
      >
        <img
          src="https://api.producthunt.com/widgets/embed-image/v1/top-post-topic-badge.svg?post_id=404298&theme=dark&period=weekly&topic_id=93"
          alt="Record&#0032;3&#0046;0 - Support&#0032;layer&#0032;built&#0032;for&#0032;B2B&#0032;SaaS | Product Hunt"
          style={{ width: `200px`, height: `43px` }}
          width="250"
          height="54"
        />
      </a>
      <a
        href="https://www.producthunt.com/posts/record-3-0?utm_source=badge-top-post-topic-badge&utm_medium=badge&utm_souce=badge-record&#0045;3&#0045;0"
        target="_blank"
        className='border border-white rounded-lg flex'
      >
        <img
          src="https://api.producthunt.com/widgets/embed-image/v1/top-post-topic-badge.svg?post_id=404298&theme=dark&period=weekly&topic_id=268"
          alt="Record&#0032;3&#0046;0 - Support&#0032;layer&#0032;built&#0032;for&#0032;B2B&#0032;SaaS | Product Hunt"
          style={{ width: `200px`, height: `43px` }}
          width="250"
          height="54"
        />
      </a>
      <a
        href="https://www.producthunt.com/posts/record-3-0?utm_source=badge-top-post-topic-badge&utm_medium=badge&utm_souce=badge-record&#0045;3&#0045;0"
        target="_blank"
        className='border border-white rounded-lg flex'
      >
        <img
          src="https://api.producthunt.com/widgets/embed-image/v1/top-post-topic-badge.svg?post_id=404298&theme=dark&period=weekly&topic_id=267"
          alt="Record&#0032;3&#0046;0 - Support&#0032;layer&#0032;built&#0032;for&#0032;B2B&#0032;SaaS | Product Hunt"
          style={{ width: `200px`, height: `43px` }}
          width="250"
          height="54"
        />
      </a>
    </div>
  )
}

export default Badges
