import  { FC } from 'react';

interface SVGProps {
  svgCode: string
}

const SVG:FC<SVGProps> = ({svgCode}) => {
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: svgCode,
      }}
    />
  );
};

export default SVG;