import React, { useEffect, useState } from 'react'
import { NavLink, Outlet } from 'react-router-dom'
import Layout from '../layouts/Layout'
import { Mixpanel } from '../mixPanel/mixPanel'
import { classNames } from '../utils/functions'

const Resources = () => {
  const [resourceToShow, setResourceToShow] = useState('webinars')
  useEffect(() => {
    Mixpanel.track('Resources Page View', {})
    console.log(resourceToShow)
    if(window.scrollY > 0) {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }, [])

  useEffect(() => {

  }, [])

  return (
    <Layout withCircles={false}>
      <div className="flex flex-col md:flex-row container mx-auto md:mt-12">
        <div className="p-8 md:pr-8">
          <h1 className="text-2xl font-semibold">Categories</h1>
          <div className="mt-4 flex flex-col">
            <NavLink
              to={'/resources'}
              // onClick={() => setResourceToShow('all')}

              // className={({ isActive }) =>
              //   classNames(
              //     isActive
              //       ? 'border-l-2 pl-1 border-tuto-primary'
              //       : 'font-light hover:text-tuto-primary'
              //   )
              // }
            >
              All
            </NavLink>
            <NavLink
              // onClick={() => setResourceToShow('webinars')}
              to={'/resources/webinars'}
              className={({ isActive }) =>
                classNames(
                  isActive
                    ? 'border-l-2 pl-1 border-tuto-primary'
                    : 'font-light hover:text-tuto-primary'
                )
              }
            >
              Webinars
            </NavLink>
            <NavLink
              // onClick={() => setResourceToShow('videos')}
              to={'/resources/videos'}
              className={({ isActive }) =>
                classNames(
                  isActive
                    ? 'border-l-2 pl-1 border-tuto-primary'
                    : 'font-light hover:text-tuto-primary'
                )
              }
            >
              Videos
            </NavLink>
          </div>
        </div>
        <div className="flex px-8 flex-col flex-wrap md:flex-row">
          <Outlet />
        </div>
      </div>
    </Layout>
  )
}

export default Resources
