/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {
  ArrowRightIcon,
  ExclamationIcon,
  XIcon
} from '@heroicons/react/outline'
import { Mixpanel } from '../mixPanel/mixPanel'
import Form from './form'

interface ModalProps {
  setOpen: any
  isOpen: boolean
  isVideo: boolean
  popupEvent?: boolean
  href?: string
  videoSrc?: string
}

const Modal: React.FunctionComponent<ModalProps> = ({
  setOpen,
  isOpen,
  isVideo,
  href,
  popupEvent,
  videoSrc
}) => {
  // const cancelButtonRef = useRef(null)
  const [showForm, setShowForm] = useState(false)

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={() => setOpen(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"  onClick={() => setOpen(false)}/>
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-center sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-2xl sm:w-full sm:p-6">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className=" rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="">
                  {/* <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationIcon
                      className="h-6 w-6 text-red-600"
                      aria-hidden="true"
                    />
                  </div> */}
                  <div
                    className={
                      !isVideo
                        ? 'flex flex-col justify-center items-center mt-3 text-center sm:mt-0  sm:text-left'
                        : 'mt-3 text-center sm:mt-0 sm:text-left'
                    }
                  >
                    <Dialog.Title
                      as="h3"
                      className="text-lg px-3 text-center leading-6 font-medium text-gray-900 mb-4"
                    >
                      <b className="px-6">
                        {isVideo
                          ? 'How It Works?'
                          : popupEvent
                          ? 'How to understand you customers better webinar alert!'
                          : 'See It In Action!'}
                      </b>
                    </Dialog.Title>
                    <div className="">
                      {popupEvent ? (
                        <div className="text-center flex flex-col items-center">
                          <p className="mb-4">
                            At Record by tuto, we believe in understanding our
                            customers better. we invite you to our first webinar
                            with Efrat Hagoel, HPE's Delivery Manager, and
                            Former Microsoft Solutions Specialist Manager. To
                            secure your spot & join us on August 10 at 10:00 AM
                            EST.
                          </p>
                          <img
                            className="max-w-xs md:max-w-sm mb-8"
                            src="https://assets.torecord.it/efrathagoel.png"
                          />
                          <button
                            onClick={() => {
                              Mixpanel.track(
                                'Clicked on webinar register now',
                                {}
                              )
                              window.open('https://bit.ly/3zVDPTO')
                            }}
                            className="inline-flex items-center px-4 py-2 border border-black text-base font-medium rounded-md  bg-tuto-secondary hover:bg-tuto-secondary-hover"
                          >
                            Register Now
                          </button>
                        </div>
                      ) : isVideo ? (

                        videoSrc ? <div>
                          <video controls autoPlay src={videoSrc}/>
                        </div> :
                        <iframe
                          width={window.innerWidth > 600 ? '450' : '350'}
                          height="300"
                          src="https://www.youtube.com/embed/HXuw0TPMtuw?autoplay=1"
                          frameBorder="0"
                          allow="autoplay; encrypted-media"
                          allowFullScreen
                          title="video"
                        />
                      ) : (
                        <div className="">
                          <div className="sm:mt-4">
                            {showForm
                              ? 'Our playground experience is available only on desktop , Please enter your work mail below and check it on desktop in order to get the most of our playground experience '
                              : 'You are about to open our Record playground experience. You’ll be redirected to Record app, and logged into a playground user. In order to exit playground environment, you will have to log out, and only than you’ll be able to signup or login.'}
                          </div>
                          <div className="sm:mt-4">
                            {showForm ? (
                              <div className="mt-4">
                                <Form formType="playground" />
                              </div>
                            ) : (
                              <button
                                type="submit"
                                onClick={() =>
                                  window.innerWidth < 678
                                    ? setShowForm(true)
                                    : (window.location.href = href || '')
                                }
                                className="flex items-center justify-center w-full py-3 px-16 rounded-md shadow bg-tuto-secondary border border-black font-medium  focus:outline-none hover:bg-tuto-secondary-hover transition duration-300  "
                              >
                                <ArrowRightIcon className="h-5 w-5 mr-4" />
                                Go to Record Playground
                              </button>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {/* <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => setOpen(false)}
                  >
                    Deactivate
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={() => setOpen(false)}
                  >
                    Cancel
                  </button>
                </div> */}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default Modal
