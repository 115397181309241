import { ArrowLeftIcon, PlayIcon, MailIcon } from '@heroicons/react/outline'
import React, { useEffect, useState } from 'react'
import { Mixpanel } from '../mixPanel/mixPanel'
import youtube from '../assets/youtube.svg'
import linkedin from '../assets/linkedin.svg'
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import { navigation } from '../layouts/navigation'
import Form from './form'
import SVG from './SVG'

interface InternalMenuProps {
  setInternalMenu: any
  subMenuToShow: string
}

const integrationIcons = {
  zendesk: `<svg xmlns="http://www.w3.org/2000/svg" class='h-4 w-4 fill-white' width="52.33" height="39.662" viewBox="0 0 52.33 39.662">
  <path id="Icon_simple-zendesk" data-name="Icon simple-zendesk" d="M24.17,44.019H0L24.17,14.839V44.019Zm28.16,0H28.16a12.085,12.085,0,1,1,24.17,0ZM28.16,33.545V4.358H52.33ZM24.17,4.358A12.085,12.085,0,1,1,0,4.362H24.17v0Z" transform="translate(0 -4.357)"/>
</svg>`,
  intercom: `<svg xmlns="http://www.w3.org/2000/svg" class='h-4 w-4 fill-white' width="45.375" height="45.375" viewBox="0 0 45.375 45.375">
<path id="Icon_awesome-intercom" data-name="Icon awesome-intercom" d="M39.7,2.25H5.672A5.676,5.676,0,0,0,0,7.922V41.953a5.676,5.676,0,0,0,5.672,5.672H39.7a5.676,5.676,0,0,0,5.672-5.672V7.922A5.676,5.676,0,0,0,39.7,2.25ZM28.734,10.565a1.514,1.514,0,0,1,3.028,0V30.772a1.514,1.514,0,0,1-3.028,0Zm-7.556-.76a1.514,1.514,0,0,1,3.028,0V31.734a1.514,1.514,0,0,1-3.028,0Zm-7.566.76a1.514,1.514,0,0,1,3.028,0V30.772a1.514,1.514,0,0,1-3.028,0ZM6.047,13.594a1.514,1.514,0,0,1,3.028,0V27.2a1.514,1.514,0,0,1-3.028,0v-13.6ZM38.8,36.666c-7.373,6.381-24.48,6.624-32.218,0a1.512,1.512,0,0,1,1.965-2.3c6.675,5.6,21.887,5.459,28.289,0a1.512,1.512,0,0,1,1.965,2.3Zm.527-9.47a1.514,1.514,0,0,1-3.028,0v-13.6a1.514,1.514,0,0,1,3.028,0Z" transform="translate(0 -2.25)"/>
</svg>`
}

const InternalMenu: React.FC<InternalMenuProps> = ({
  setInternalMenu,
  subMenuToShow
}) => {
  const [showForm, setShowForm] = useState(false)
  const [routes, setRoutes] = useState(navigation[0].children)

  useEffect(() => {
    const routesToShow = navigation.filter(
      (route: any) => route.name === subMenuToShow
    )
    setRoutes(routesToShow[0].children)
  }, [subMenuToShow])

  return (
    <div className="my-2">
      <div className=" px-2 flex flex-col justify-between relative overflow-hidden">
        {/* <div className="purple_circle"></div> */}
        <div className="mb-4">
          <button
            onClick={() => setInternalMenu(false)}
            className="p-1 flex items-center rounded-md glass-effect bg-white bg-opacity-20"
          >
            <ArrowLeftIcon className="h-4 w-4 mr-1" />
            back
          </button>
        </div>
        {/* <div className="border-b py-1 mb-4">
          <h1 className="text-xl font-semibold">See it in action</h1>
        </div>
        <p className="text-sm text-gray-500 mb-4">
          Use our playground mode to evaluate the product. We give you full
          control
        </p> */}
        {/* <div>
          {!showForm && (
            <button
              onClick={() => {
                Mixpanel.track(`Clicked on Playground`, {
                  location: 'resources menu'
                })
                setShowForm(true)
              }}
              className="button font-semibold"
            >
              <PlayIcon className="mr-2 h-5 w-5" />
              Playground
            </button>
          )}
          {showForm && <Form formType="playground" />}
        </div> */}
      </div>
      <div className="p-2 relative">
        <div className="blue_circle"></div>
        {routes &&
          routes.map((item: any) =>
            item.internalLink ? (
              item.path.includes('#') ? (
                <HashLink
                  key={item.name}
                  to={item.path}
                  className="-m-3 flex items-start rounded-lg p-3 transition duration-150 ease-in-out hover:bg-gray-50"
                  onClick={() =>
                    Mixpanel.track(`Clicked on ${item.name}`, {
                      location: 'resources menu'
                    })
                  }
                >
                  <div
                    className={`flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-md ${item.background} text-white sm:h-12 sm:w-12`}
                  >
                    <item.icon className="h-6 w-6" aria-hidden="true" />
                  </div>
                  <div className="ml-4">
                    <p className="text-base font-medium ">
                      {item.name}
                    </p>
                    <p className="mt-1 text-sm ">
                      {item.description}
                    </p>
                  </div>
                </HashLink>
              ) : (
                <Link
                  key={item.name}
                  to={item.path}
                  className="-m-3 flex items-start rounded-lg p-3 transition duration-150 ease-in-out hover:bg-gray-50"
                  onClick={() => {
                    Mixpanel.track(`Clicked on ${item.name}`, {
                      location: 'resources menu'
                    })
                  }}
                >
                  <div
                    className={`flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-md ${item.background} text-white sm:h-12 sm:w-12`}
                  >
                    <item.icon className="h-6 w-6" aria-hidden="true" />
                  </div>
                  <div className="ml-4">
                    <p className="text-base font-medium ">
                      {item.name}
                    </p>
                    <p className="mt-1 text-sm ">
                      {item.description}
                    </p>
                  </div>
                </Link>
              )
            ) : (
              <a
                key={item.name}
                href={item.path}
                className="-m-3 flex items-start rounded-lg p-3 transition duration-150 ease-in-out hover:bg-gray-50"
                onClick={() =>
                  Mixpanel.track(`Clicked on ${item.name}`, {
                    location: 'resources menu'
                  })
                }
              >
                <div
                  className={`flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-md ${item.background} text-white sm:h-12 sm:w-12`}
                >
                  <item.icon className="h-6 w-6" aria-hidden="true" />
                </div>
                <div className="ml-4">
                  <p className="text-base font-medium text-gray-900">
                    {item.name}
                  </p>
                  <p className="mt-1 text-sm text-gray-500">
                    {item.description}
                  </p>
                </div>
              </a>
            )
          )}
      </div>
      
        {subMenuToShow === 'Product' ? (
          <div className="flex gap-2 border-t pt-4 items-center">
            <Link
              to="/integrations/zendesk"
              className="bg-tuto-fourth p-2 rounded relative cursor-pointer"
            >
              <SVG svgCode={integrationIcons.zendesk} />
            </Link>

            <Link
              to="/integrations/intercom"
              className="bg-tuto-fourth p-2 rounded relative cursor-pointer"
            >
              <SVG svgCode={integrationIcons.intercom} />
            </Link>
          </div>
        ) : (
          <div className='border-t pt-4 flex items-center'>
            <a
              target={'_blank'}
              className="p-1 rounded-full bg-tuto-secondary mr-4"
              href="https://www.linkedin.com/company/tutoit/"
              onClick={() =>
                Mixpanel.track(`Clicked on Linkedin`, {
                  location: 'resources menu'
                })
              }
            >
              <img className="w-6 h-6" src={linkedin} />
            </a>
            <a
              className="p-1 rounded-full bg-tuto-secondary mr-4"
              href="mailto:support@torecord.it"
              onClick={() =>
                Mixpanel.track(`Clicked on Mail`, {
                  location: 'resources menu'
                })
              }
            >
              <MailIcon className="w-6 h-6" />
            </a>
            <a
              target={'_blank'}
              className="p-1 rounded-full bg-tuto-secondary"
              href="https://www.youtube.com/channel/UCXIvT0oqvAnSY_2_Z4IkiFA"
              onClick={() =>
                Mixpanel.track(`Clicked on youtube`, {
                  location: 'resources menu'
                })
              }
            >
              <img className="w-6 h-6" src={youtube} />
            </a>
          </div>
        )}
      
    </div>
  )
}

export default InternalMenu
