export const integrations = [{
  name: 'Zendesk',
  icon: `<svg xmlns="http://www.w3.org/2000/svg" class='h-20 w-20' width="52.33" height="39.662" viewBox="0 0 52.33 39.662">
    <path id="Icon_simple-zendesk" data-name="Icon simple-zendesk" d="M24.17,44.019H0L24.17,14.839V44.019Zm28.16,0H28.16a12.085,12.085,0,1,1,24.17,0ZM28.16,33.545V4.358H52.33ZM24.17,4.358A12.085,12.085,0,1,1,0,4.362H24.17v0Z" transform="translate(0 -4.357)"/>
  </svg>`,
  description: 'Request and access each Record directly from your preferred support OS, and view everything in its proper context.',
  img: 'https://assets.torecord.it/zen-page-main-img.jpeg',
  video: 'https://assets.torecord.it/zen-page-vid.mp4',
  connectUrl: 'https://www.zendesk.com/marketplace/apps/support/865393/record/',
  bullets: [
    {
      badge: '2-way sync',
      title: 'Personalize The Experience',
      img: 'https://assets.torecord.it/zen-page-1.png',
      text: 'Enhance the user experience by utilizing your Zendesk data. View all records created by the user in the ticket context.'
    },
    {
      badge: 'Creat new ticket',
      title: 'Create New Ticket With All The Information Needed In It.',
      img: 'https://assets.torecord.it/zen-page-2.png',
      text: 'Eliminate back-and-forth communication. Effortlessly obtain all necessary data and immediately resolve any issue by accessing any ticket in your Zendesk account with Record.'
    },
    {
      badge: 'Record Request',
      title: 'Work 15X Faster',
      img: 'https://assets.torecord.it/zen-page-3.png',
      text: 'Encourage users to record their issues directly from the ticket. This allows you to view all relevant information and the user recording within the context in which it was created, enhancing the ticket flow.'
    }
  ]
},
{
  name: 'Intercom',
  icon: `<svg xmlns="http://www.w3.org/2000/svg" class='h-20 w-20' width="45.375" height="45.375" viewBox="0 0 45.375 45.375">
    <path id="Icon_awesome-intercom" data-name="Icon awesome-intercom" d="M39.7,2.25H5.672A5.676,5.676,0,0,0,0,7.922V41.953a5.676,5.676,0,0,0,5.672,5.672H39.7a5.676,5.676,0,0,0,5.672-5.672V7.922A5.676,5.676,0,0,0,39.7,2.25ZM28.734,10.565a1.514,1.514,0,0,1,3.028,0V30.772a1.514,1.514,0,0,1-3.028,0Zm-7.556-.76a1.514,1.514,0,0,1,3.028,0V31.734a1.514,1.514,0,0,1-3.028,0Zm-7.566.76a1.514,1.514,0,0,1,3.028,0V30.772a1.514,1.514,0,0,1-3.028,0ZM6.047,13.594a1.514,1.514,0,0,1,3.028,0V27.2a1.514,1.514,0,0,1-3.028,0v-13.6ZM38.8,36.666c-7.373,6.381-24.48,6.624-32.218,0a1.512,1.512,0,0,1,1.965-2.3c6.675,5.6,21.887,5.459,28.289,0a1.512,1.512,0,0,1,1.965,2.3Zm.527-9.47a1.514,1.514,0,0,1-3.028,0v-13.6a1.514,1.514,0,0,1,3.028,0Z" transform="translate(0 -2.25)"/>
  </svg>`,
  description: 'Request and access each Record directly from your preferred support OS, and view everything in its proper context.',
  img: 'https://assets.torecord.it/intercom-main-page.png',
  video: 'https://assets.torecord.it/IntercomHowToUse.mp4',
  connectUrl: 'https://www.intercom.com/app-store/?app_package_code=record&search=record',
  bullets: [
    {
      badge: '2-way sync',
      title: 'Personalize The Experience',
      img: 'https://assets.torecord.it/intercom-page-1.png',
      text: 'Enhance the user experience by utilizing your Intercom data. View all records created by the user in the ticket context.'
    },
    {
      badge: 'Creat new ticket',
      title: 'Create New Ticket With All The Information Needed In It.',
      img: 'https://assets.torecord.it/intercom-page-2.png',
      text: 'Eliminate back-and-forth communication. Effortlessly obtain all necessary data and immediately resolve any issue by accessing any ticket in your Intercom account with Record.'
    },
    {
      badge: 'Record Request',
      title: 'Work 15X Faster',
      img: 'https://assets.torecord.it/intercom-page-3.png',
      text: 'Encourage users to record their issues directly from the ticket. This allows you to view all relevant information and the user recording within the context in which it was created, enhancing the ticket flow.'
    }
  ]
}]
