import React, { useEffect, useState } from 'react'
import { useMatch, Link } from 'react-router-dom'
import Layout from '../layouts/Layout'
import { resources } from '../content/webinars'

const SingleVideo = () => {
  const resourceSlug: any = useMatch('/resources/videos/:slug')

  const [resource, setResource] = useState<any>('')

  useEffect(() => {
    const resourceToFind = resources.find(
      (resource) => resource.slug === resourceSlug.params.slug
    )

    setResource(resourceToFind)
    if(window.scrollY > 0) {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }, [])

  return (
    <Layout withCircles={false}>
      <div className="flex flex-col justify-center md:flex-row md:justify-between p-4 md:p-0 mt-8 container mx-auto">
        <div>
          <div className="flex-grow items-start">
            <span className="py-1 px-2 rounded bg-tuto-secondary">
              {resource.type}
            </span>

            <div>
              <h1 className="text-3xl my-4">{resource.title}</h1>
            </div>
          </div>
          <div className="md:mb-12">
            <video
            controls
              className="max-w-xs md:max-w-3xl shadow-lg rounded-lg"
              src={resource.videoSrc}
            />
            <div className="mt-12 lg:pr-20" dangerouslySetInnerHTML={{ __html: resource.description }}></div>
          </div>
        </div>
        <div className='mt-10 md:mt-24'>
          <div className="border rounded-md">
            {resources
              .filter((resource: any) => resource.type === 'Video')
              .map((video: any) => (
                <a key={video.slug} className={`cursor-pointer `} href={`/resources/videos/${video.slug}`}>
                  <div className={`flex mb-4 items-center  p-4 justify-between ${video.slug === resource.slug ? 'border bg-gray-100 border-tuto-primary' : 'border-b'}`}>
                    <div className='w-24 mr-4 shadow'>
                      <img className="max-w-full" src={video.imgSrc} />
                    </div>
                    <div className='mr-4'> {video.title}</div>
                    <div className=''>{video.duration}</div>
                  </div>
                </a>
              ))}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default SingleVideo
