import { useEffect, useState } from 'react'
import ScrollTabs from '../components/ScrollTabs'
import Layout from '../layouts/Layout'
import { features } from '../content/features'
import { Mixpanel } from '../mixPanel/mixPanel'
import { useParams } from 'react-router-dom'
import SVG from '../components/SVG'

const FeaturePage = () => {
  const [fearure, setFeature] = useState({
    name: '',
    description: '',
    img: '',
    video: '',
    tabs: [],
    icon: '',
    color: ''
  })
  const params = useParams()

  useEffect(() => {
    const foundFeature: any = features.filter(
      (found: any) => found.route.toLowerCase() === params.feature
    )
    if (foundFeature && foundFeature.length > 0) {
      Mixpanel.track(`${foundFeature[0].name} Page View`, {})
      Mixpanel.register({ Page: foundFeature[0].name })
      setFeature({
        name: foundFeature[0].name,
        description: foundFeature[0].description,
        img: foundFeature[0].img,
        video: foundFeature[0].video,
        tabs: foundFeature[0].tabs,
        icon: foundFeature[0].icon,
        color: foundFeature[0].color
      })
    }
    if(window.scrollY > 0) {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }, [params])


  return (
    <Layout withCircles={true}>
      <div className="flex flex-col items-center min-h-70v z-50 justify-center">
        <div className={`${fearure.color} p-4 rounded-xl text-white mb-4`}>
          <SVG svgCode={fearure.icon} />
        </div>
        <h1 className=" text-center text-5xl font-bold font-montserrat sm:mt-5 sm:text-6xl lg:mt-4 xl:text-6xl leading-normal">
          {fearure.name}
        </h1>
        <p className="mt-3 text-center  text-base sm:mt-5 sm:text-xl md:max-w-2xl lg:text-lg xl:text-xl">
          {fearure.description}
        </p>
      </div>
      <div className="flex items-center justify-center">
        <div className="p-12 lg:items-startp-0 max-w-5xl  overflow-hidden">
          <video controls src={fearure.video} className="shadow-small rounded-2xl" />
        </div>
      </div>
      <div className="my-20">
        {fearure.tabs && fearure.tabs.length > 0 && (
          <ScrollTabs tabs={fearure.tabs} />
        )}
      </div>
      <div className='flex flex-col lg:flex-row justify-center glass-effect bg-white bg-opacity-20 items-center lg:px-24 py-20   my-20 lg:mx-60'>
        <div className='p-10 w-1/2 flex items-center justify-center'>
          <img src='https://assets.torecord.it/PersonFace.png' className='rounded-full lg:w-40 shadow-small relative'/>
        </div>
        <div className='flex flex-col gap-10 flex-grow w-1/2'>
          <h1 className='text-3xl font-semibold'>We cut support cycle by 27%</h1>
          <p className='lg:pr-16'>
          We use Record Capture as the starting point for creating a new ticket directly in our Zendesk account, making it easy to provide laser-focused support.
          </p>
          <div>
            <p className='font-semibold'>Antony Williams</p>
            <div className='font-bold'>
              <span className='text-red-500 mr-2'>VARIN</span>
              
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default FeaturePage
