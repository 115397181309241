import { useState } from 'react'
import { Mixpanel } from '../mixPanel/mixPanel'
import { HashLink } from 'react-router-hash-link'
import {
  QuestionMarkCircleIcon,
  DocumentIcon,
  ShieldCheckIcon,
  InboxIcon,
  PlayIcon
} from '@heroicons/react/solid'
import { Link } from 'react-router-dom'
import Modal from './Modal'

const FooterMenu = () => {
  const [isOpen, setOpen] = useState(false)
  const recordModeHref = `${process.env.REACT_APP_DASHBOARD}login?playground=true`
  return (
    <div>
      <div className="border glass-effect bg-white bg-opacity-20 rounded-lg grid grid-cols-1 md:grid-cols-2 md:justify-items-center px-4 py-16 mx-auto my-16">
        <div className="flex flex-col">
          <div className="text-xl font-semibold">Have any question?</div>
          <HashLink
            onClick={() =>
              Mixpanel.track('user clicked on how it works from pricing', {})
            }
            to="/#video"
            className="flex flex-row my-4 mt-8"
          >
            <div className="flex flex-row items-center">
              <div
                className={`flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-md bg-tuto-primary text-white sm:h-12 sm:w-12`}
              >
                <QuestionMarkCircleIcon
                  className="h-6 w-6"
                  aria-hidden="true"
                />
              </div>
              <div className="flex flex-col ml-2">
                <span className="text-md font-semibold">See how it works</span>
                <span className="text-sm">See helpful videos</span>
              </div>
            </div>
          </HashLink>
          <Link
            onClick={() =>
              Mixpanel.track('user clicked on Docs from pricing', {})
            }
            to="/docs"
            className="flex flex-row my-4"
          >
            <div className="flex flex-row items-center">
              <div
                className={`flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-md bg-tuto-yellow text-white sm:h-12 sm:w-12`}
              >
                <DocumentIcon className="h-6 w-6" aria-hidden="true" />
              </div>
              <div className="flex flex-col ml-2">
                <span className="text-md font-semibold">See our docs</span>
                <span className="text-sm">Manuals</span>
              </div>
            </div>
          </Link>
          <Link
            onClick={() =>
              Mixpanel.track(
                'user clicked on security and privacy from pricing',
                {}
              )
            }
            to="/security"
            className="flex flex-row my-4"
          >
            <div className="flex flex-row items-center">
              <div
                className={`flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-md bg-tuto-light-blue text-white sm:h-12 sm:w-12`}
              >
                <ShieldCheckIcon className="h-6 w-6" aria-hidden="true" />
              </div>
              <div className="flex flex-col ml-2">
                <span className="text-md font-semibold">
                  Privacy & Security
                </span>
                <span className="text-sm">Look at all of our guidelines</span>
              </div>
            </div>
          </Link>
        </div>
        <div className="flex flex-col">
          <div className="text-xl font-semibold">Contact Us</div>
          <a
            href="mailto:support@torecord.it"
            className="flex flex-row my-4 mt-8"
          >
            <div className="flex flex-row items-center">
              <div
                className={`flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-md bg-tuto-yellow text-white sm:h-12 sm:w-12`}
              >
                <InboxIcon className="h-6 w-6" aria-hidden="true" />
              </div>
              <div className="flex flex-col ml-2">
                <span className="text-md font-semibold">Send Us Email</span>
                <span className="text-sm">See helpful videos</span>
              </div>
            </div>
          </a>
          <a
            onClick={() =>
              Mixpanel.track('user clicked on how linkedin from pricing', {})
            }
            target={'_blank'}
            href="https://www.linkedin.com/company/tutoit/"
            className="flex flex-row my-4"
          >
            <div className="flex flex-row items-center">
              <div
                className={`flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-md bg-tuto-primary text-white sm:h-12 sm:w-12`}
              >
                <ShieldCheckIcon className="h-6 w-6" aria-hidden="true" />
              </div>
              <div className="flex flex-col ml-2">
                <span className="text-md font-semibold">LinkedIn</span>
                <span className="text-sm">See helpful videos</span>
              </div>
            </div>
          </a>
        </div>
        <div className="flex flex-col">
          {/* <div className="text-xl font-semibold">Want to see it in action?</div>
          <div
            onClick={() => {
              Mixpanel.track(
                'user clicked on see it in action from pricing',
                {}
              )
              setOpen(true)
            }}
            className="flex flex-row mt-8 cursor-pointer"
          > */}
            {/* <div className="flex flex-row items-center">
              <div
                className={`flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-md bg-tuto-light-blue text-white sm:h-12 sm:w-12`}
              >
                <PlayIcon className="h-6 w-6" aria-hidden="true" />
              </div>
              <div className="flex flex-col ml-2">
                <span className="text-md font-semibold">
                  Enter to playground mode
                </span>
                <span className="text-sm">Check the product</span>
              </div>
            </div> */}
          {/* </div> */}
        </div>
      </div>
      <Modal
        isOpen={isOpen}
        setOpen={setOpen}
        isVideo={false}
        href={recordModeHref}
      />
    </div>
  )
}

export default FooterMenu
