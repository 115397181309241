export const tabsContent = [
  
  {
    title: 'Record Investigations',
    subTitle: 'Turn Raw Data into Actionable Insights',
    content: ['In an era where data overload is common, Record goes beyond mere data capture. Our platform enables you to navigate through the data to identify the specific issues affecting not just the user who reported the problem, but everyone experiencing the same issue. Utilize Record`s robust Investigation to pinpoint the root cause quickly and efficiently.'],
    video: 'https://assets.torecord.it/Investigation_onbording.mp4',
    route: 'analytics'
  },
  {
    title: 'Record Inbox',
    subTitle: 'AI-Powered Prioritization for Your Attention',
    content: ['Achieve complete control over the UI elements that can be recorded, block or mask elements for maximum security, or keep user information anonymous to make your record account fully compliant with privacy regulations such as GDPR, ISO27001 and SOC 2.'],
    video: 'https://assets.torecord.it/InboxOnborading.mp4',
    route: 'privacy-security'
  },
  {
    title: 'Record Feed Events',
    subTitle: 'Effortless Data Capture for Proactive Issue Resolution',
    content: ['As an AI observability layer, Record captures all critical data to aid in resolving technical issues and alerting you before problems escalate. This includes console error logs, full network requests and responses, user activity, browser information, backend logs, custom data, and more. With Record, you`ll never have to ask your users for additional information again.'],
    img: 'https://assets.torecord.it/feed-events.png',
    route: 'feed'
  },
  {
    title: 'Security & Privacy',
    subTitle: 'Trustworthy and Compliant Data Protection',
    content: ['Record was built with privacy and security as top priorities. We are GDPR-compliant and ISO-27001 certified from the get-go. We`re also in the process of obtaining SOC-2 certification. Our platform includes data masking solutions to prevent private information from even reaching our servers, and we partner with best-in-class vendors like AWS, Google, and Paddle to ensure your data is secure.'],
    video: 'https://assets.torecord.it/record_mask_and_block.mp4',
    route: 'privacy-security'
  }
];