import './components.css'
import { Pagination, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'

const Logos = () => {
  return (
    <div className="">
      <div className="container mx-auto py-12 px-0">
        {/* <h1 className="text-2xl text-normal-black sm:text-3xl text-center mb-16 font-bold">
          Organizations who trust us
        </h1> */}
        <div className="flex items-center justify-center">
          <Swiper
            spaceBetween={10}
            slidesPerView={1}
            breakpoints={{
              640: {
                slidesPerView: 4,
                spaceBetween: 20
              },
              768: {
                slidesPerView: 5,
                spaceBetween: 40
              },
              1024: {
                slidesPerView: 6,
                spaceBetween: 20
              }
            }}
            modules={[Pagination, Autoplay]}
            // onSlideChange={() => console.log('slide change')}
            // onSwiper={(swiper) => console.log(swiper)}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false
            }}
            centeredSlides={true}
            loop={true}
          >
            <SwiperSlide>
              <div className="flex justify-center items-center ">
                <img
                  className="w-36 h-auto"
                  src="https://komodor.com/wp-content/uploads/2023/07/komodor-logo-new.svg"
                  alt="Tuple"
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="flex justify-center">
                <img
                  className="w-36 h-auto"
                  src="https://assets.torecord.it/dataloop-logo.png"
                  alt="Mirage"
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="flex justify-center">
                <img
                  className="w-36 h-auto"
                  src="https://assets.torecord.it/sparc-logo.png"
                  alt="StaticKit"
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="flex justify-center">
                <img
                  className="w-12 h-auto"
                  src="https://assets.torecord.it/lrn-logo.png"
                  alt="Transistor"
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="flex justify-center ">
                <img
                  className="w-36 h-auto"
                  src="https://assets.torecord.it/group-gps-logo.png"
                  alt="Workcation"
                />
              </div>
            </SwiperSlide>
            {/* <SwiperSlide>
              <div className="flex justify-center ">
                <img
                  className="w-36 h-auto"
                  src="https://assets.torecord.it/syte.png"
                  alt="Workcation"
                />
              </div>
            </SwiperSlide> */}
            <SwiperSlide>
              <div className="flex justify-center">
                <img
                  className="w-36 h-auto"
                  src="https://assets.torecord.it/scrimmage-logo.png"
                  alt="Workcation"
                />
              </div>
            </SwiperSlide>
            
           
            
           
          </Swiper>
        </div>
      </div>
    </div>
  )
}

export default Logos
