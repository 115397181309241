import React from 'react'

const Testimonials = () => {
  return (
    <div className="container p-4 md:p-0 mb-36 mx-auto grid grid-cols-1 md:grid-cols-4 grid-rows-10 gap-8">
      <div className="testimonial  md:col-span-2  md:row-span-4">
        <h1 className="text-xl text-tuto-fourth font-extrabold mb-8">
          <span className="text-4xl">“</span>
          <br />
          We’re wasting so much time solving the wrong issues
        </h1>
        <div className="flex items-center text-tuto-fourth">
          <div className="rounded-full border shadow-xl overflow-hidden w-16 h-16">
            <img
              className="max-w-full max-h-full"
              src="https://assets.torecord.it/nawaf.jpeg"
            />
          </div>
          <div className="flex flex-col ml-4">
            <span className="text-xl font-semibold">Nawaf Helou</span>
            <span className="text-xl">Head of Technical Support at Syte</span>
          </div>
        </div>
      </div>
      <div className="testimonial  md:col-start-3  md:col-span-2  md:row-span-6">
        <h1 className="text-xl text-tuto-fourth font-extrabold mb-8">
          <span className="text-4xl">“</span>
          <br />
          This looks really promising. We had this big issue of actually
          understanding what the issue was at our last company. We had an
          application for doctors who didn't have enough time to come on the
          call and explain their problem in detail - so they would end up
          telling just the gist of it. And our teams would then spend some time
          trying to reproduce the issue. And coincidentally we were using
          zendesk. So I could instantly connect with this.
        </h1>
        <div className="flex items-center text-tuto-fourth">
          <div className="rounded-full border shadow-xl overflow-hidden w-16 h-16">
            <img
              className="max-w-full max-h-full"
              src="https://assets.torecord.it/Ellipse_253.png"
            />
          </div>
          <div className="flex flex-col ml-4">
            <span className="text-xl font-semibold">Abhishek Singh</span>
            <span className="text-xl">Head of Support and IT at ValueFirst</span>
          </div>
        </div>
      </div>
      <div className="testimonial  md:col-start-1  md:col-span-2  md:row-span-6">
        <h1 className="text-xl text-tuto-fourth font-extrabold mb-8">
          <span className="text-4xl">“</span>
          <br />
          I think this is a great idea! I can see how it would help to speed up the process of solving customer issues.
        </h1>
        <div className="flex items-center text-tuto-fourth">
          <div className="rounded-full border shadow-xl overflow-hidden w-16 h-16">
            <img
              className="max-w-full max-h-full"
              src="https://assets.torecord.it/Ellipse_293.png"
            />
          </div>
          <div className="flex flex-col ml-4">
            <span className="text-xl font-semibold">Karol Andruszkow</span>
            <span className="text-xl">Co-Founder of BOWWE</span>
          </div>
        </div>
      </div>
      <div className="testimonial  md:col-start-3  md:col-span-2  md:row-span-4">
        <h1 className="text-xl text-tuto-fourth font-extrabold mb-8">
          <span className="text-4xl">“</span>
          <br />
          It will save time for many developers by hitting at the right point. congratulations for the launch
        </h1>
        <div className="flex items-center text-tuto-fourth">
          <div className="rounded-full border shadow-xl overflow-hidden w-16 h-16">
            <img
              className="max-w-full max-h-full"
              src="https://assets.torecord.it/Ellipse_294.png"
            />
          </div>
          <div className="flex flex-col ml-4">
            <span className="text-xl font-semibold">Varun Razora</span>
            <span className="text-xl">Head of Technical Support at Elaxer</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Testimonials
