import {
  ChartBarIcon,
  DocumentIcon,
  CameraIcon,
  AcademicCapIcon,
  ShieldCheckIcon,
  QuestionMarkCircleIcon,
  EyeIcon,
  PlayIcon,
  VideoCameraIcon,
  SearchIcon,
  CubeTransparentIcon,
  MapIcon,
  PuzzleIcon
} from '@heroicons/react/outline'
import { CubeIcon, LibraryIcon } from '@heroicons/react/solid'

export const navigation = [
  {
    name: 'Product',
    internalLink: true,
    path: '/',
    showInHeader: true,
    showInFooter: false,
    twoSides: false,
    withMedia: false,
    withIntegration: true,
    children: [
      {
        name: 'Session Recordings',
        description: 'Spot pain points, support effectively',
        path: '/record/sessions',
        icon: PlayIcon,
        background: 'bg-tuto-primary',
        internalLink: true
      },
      {
        name: 'Investigation & Monitoring',
        description: 'Find the issues before the ticket',
        path: '/record/investigation',
        icon: SearchIcon,
        background: 'bg-tuto-secondary',
        internalLink: true
      },
      {
        name: 'Record Capture',
        description: 'Capture all the data you need.',
        path: '/record/capture',
        icon: CubeTransparentIcon,
        background: 'bg-tuto-third',
        internalLink: true
      },
      {
        name: 'Record Analytics',
        description: 'Get Insights on your issues.',
        path: '/record/analytics',
        icon: ChartBarIcon,
        background: 'bg-tuto-secondary',
        internalLink: true
      },
//       {
//         name: 'Record Extension',
//         description: 'Collect, Investigate, and Resolve Technical Issues Faster.',
//         path: '/extension',
//         icon: PuzzleIcon,
//         background: 'bg-tuto-third',
//         internalLink: true
//       },
      {
        name: 'Record Security',
        description: 'Mask and block UI elements.',
        path: '/record/privacy-security',
        icon: ShieldCheckIcon,
        background: 'bg-tuto-primary',
        internalLink: true
      }
    ]
  },
  {
    name: 'Resources',
    internalLink: true,
    path: '/resources',
    showInHeader: true,
    showInFooter: false,
    twoSides: false,
    withMedia: true,
    withIntegration: false,
    children: [
      {
        name: 'Webinars & Videos',
        description: `See talks with industry leaders and helpful videos`,
        path: `/resources`,
        icon: VideoCameraIcon,
        background: 'bg-tuto-primary',
        internalLink: true
      },
      {
        name: 'Blog',
        description: `Check out Record's blog posts`,
        path: `/blog`,
        icon: AcademicCapIcon,
        background: 'bg-tuto-secondary',
        internalLink: true
      },
      {
        name: `Record's Guide`,
        description: `Record's comprehensive guide`,
        path: `/guide`,
        icon: MapIcon,
        background: 'bg-tuto-third',
        internalLink: true
      },
      {
        name: 'Docs',
        description: 'Explore our documentation',
        path: `/docs`,
        icon: DocumentIcon,
        background: 'bg-tuto-fourth',
        internalLink: true
      },
      {
        name: 'Privacy & Security',
        description: 'Our guidlines for your safety',
        path: `/security`,
        icon: ShieldCheckIcon,
        background: 'bg-tuto-primary',
        internalLink: true
      }
    ]
  },
  {
    name: 'Book a Demo',
    internalLink: true,
    path: '/book-a-demo',
    showInHeader: true,
    showInFooter: false
  },
  {
    name: 'Pricing',
    internalLink: true,
    path: '/pricing',
    showInHeader: true,
    showInFooter: false
  },
  {
    name: 'About',
    internalLink: true,
    path: '/about',
    showInHeader: true,
    showInFooter: false
  }
]
