import React, { useEffect } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'

interface LayoutProps {
  children: React.ReactNode
  withCircles: boolean
}

const Layout: React.FC<LayoutProps> = ({ children, withCircles }) => {

  useEffect(() => {

    if(window.scrollY > 0) {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }, [])

  return (
    <div className="bg-transparent bg-dark-bg">
      {withCircles && (
        <div className="overflow-hidden absolute top-0 w-full h-screen ">
          <div className="purple"></div>
          {/* <div className="yellow"></div> */}
          <div className="blue_circle hero"></div>
        </div>
      )}
      <Header />
      <div className="mx-auto min-h-screen  relative z-20">{children}</div>
      <Footer />
    </div>
  )
}

export default Layout
