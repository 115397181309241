import './App.css'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Home from './pages/Home'
import Resources from './pages/Resources'
import SingleResource from './components/SingleResource'
import Docs from './pages/Docs'
import ResourceTab from './pages/ResourceTab'
import Pricing from './pages/Pricing'
import Checkout from './pages/Checkout'
import SingleVideo from './components/SingleVideo'
import AboutUs from './pages/AboutUs'
import Zendesk from './pages/Zendesk'
import FeaturePage from './pages/FeaturePage'
import { Mixpanel } from './mixPanel/mixPanel'
import SVG from './components/SVG'
import recordIcon from './assets/recordIcon.svg'
import BookDemo from './pages/BookDemo'


declare global {
  interface Window {
    Intercom: any;
  }
}


window.Intercom("boot", {
  api_base: "https://api-iam.intercom.io",
  app_id: "l9ow8f3y"
});

function App() {
  return (
    <div className="App bg-transparent">
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route
            path="/docs"
            element={
              <Docs
                source={
                  'https://v2-embednotion.com/Record-Knowledge-base-ee67cd6aa6c14fb5bb861d2a546262d5?pvs=4'
                }
                type={'Docs'}
              />
            }
          />
          <Route
            path="/terms"
            element={
              <Docs
                source={
                  'https://doc.clickup.com/3623824/p/h/3ejwg-9922/118d15191efc6f9'
                }
                type={'Terms'}
              />
            }
          />
          <Route
            path="/privacy-policy"
            element={
              <Docs
                source={
                  'https://doc.clickup.com/3623824/p/h/3ejwg-9942/f101b864637328d'
                }
                type={'Privacy'}
              />
            }
          />
          <Route
            path="/guide"
            element={
              <Docs
                source={
                  'https://blog.torecord.it/en/record-comprehensive-guide-to-mastering-technical-issue-resolution'
                }
                type={'Guide'}
              />
            }
          >
          </Route>
           <Route
            path="/the-importance-of-syncing-support-and-rnd-for-faster-issue-resolution-1-0-0-0-0"
            element={
              <Docs
                source={
                  'https://blog.torecord.it/the-importance-of-syncing-support-and-rnd-for-faster-issue-resolution-1-0-0-0-0'
                }
                type={'Blog'}
              />
            }
          ></Route>
          <Route
            path="/the-importance-of-syncing-support-and-rnd-for-faster-issue-resolution-1-0-0-0"
            element={
              <Docs
                source={
                  'https://blog.torecord.it/the-importance-of-syncing-support-and-rnd-for-faster-issue-resolution-1-0-0-0'
                }
                type={'Blog'}
              />
            }
          ></Route>
          <Route
            path="/the-importance-of-syncing-support-and-rnd-for-faster-issue-resolution-1-0-0"
            element={
              <Docs
                source={
                  'https://blog.torecord.it/the-importance-of-syncing-support-and-rnd-for-faster-issue-resolution-1-0-0'
                }
                type={'Blog'}
              />
            }
          ></Route>
          <Route
            path="/the-importance-of-syncing-support-and-rnd-for-faster-issue-resolution-1-0"
            element={
              <Docs
                source={
                  'https://blog.torecord.it/the-importance-of-syncing-support-and-rnd-for-faster-issue-resolution-1-0'
                }
                type={'Blog'}
              />
            }
          ></Route>
          <Route
            path="/the-importance-of-syncing-support-and-rnd-for-faster-issue-resolution-1"
            element={
              <Docs
                source={
                  'https://blog.torecord.it/the-importance-of-syncing-support-and-rnd-for-faster-issue-resolution-1'
                }
                type={'Blog'}
              />
            }
          ></Route>
                    <Route
            path="/the-importance-of-syncing-support-and-rnd-for-faster-issue-resolution-0"
            element={
              <Docs
                source={
                  'https://blog.torecord.it/the-importance-of-syncing-support-and-rnd-for-faster-issue-resolution-0'
                }
                type={'Blog'}
              />
            }
          ></Route>
                    <Route
            path="/the-importance-of-syncing-support-and-rnd-for-faster-issue-resolution"
            element={
              <Docs
                source={
                  'https://blog.torecord.it/the-importance-of-syncing-support-and-rnd-for-faster-issue-resolution'
                }
                type={'Blog'}
              />
            }
          ></Route>
            <Route 
            path="/blog/:blog_slug"
            element={
              <Docs
                source={
                  'https://blog.torecord.it'
                }
                type={'Blog'}
              />
            }
            />
          <Route 
            path="/blog"
            element={
              <Docs
                source={
                  'https://blog.torecord.it'
                }
                type={'Blog'}
              />
            }
            />
          <Route
            path="/security"
            element={
              <Docs
                source={
                  'https://doc.clickup.com/3623824/p/3ejwg-7442/privacy-policy-termsof-service'
                }
                type={'Security'}
              />
            }
          />
          <Route
            path="/extension"
            element={
              <Docs
                source={
                  'https://doc.clickup.com/3623824/d/h/3ejwg-6582/9da4702910666ac'
                }
                type={'Extension'}
              />
            }
          />
          <Route path="/resources" element={<Resources />}>
            <Route index element={<ResourceTab />} />
            <Route path="videos" element={<ResourceTab />} />
            <Route path="webinars" element={<ResourceTab />} />
          </Route>
          <Route path="resources/webinars/:slug" element={<SingleResource />} />
          <Route path="resources/videos/:slug" element={<SingleVideo />} />
          <Route path="book-a-demo" element={<BookDemo />} />

          <Route path="/*" element={<Home />} />
          <Route path="pricing" element={<Pricing />} />
          <Route path="checkout/:product_id" element={<Checkout />} />
          <Route path="about" element={<AboutUs />} />
          <Route path="integrations">
            <Route path=":id" element={<Zendesk />} />
          </Route>
          <Route path="record" >
            <Route path=':feature' element={<FeaturePage />} />
          </Route>
        </Routes>
      </Router>
      {/* <div className="fixed bottom-0 right-0 mb-4 mr-4 flex flex-col items-center z-50 hover:cursor-pointer"
      onClick={() => {
        Mixpanel.track('Clicked on Create Your Own', {
          Location: 'fixed right bottom'
        })
        if (!window.location.href.includes('isrecording=true')) {
            console.log(`!window.location.href.includes('isrecording=true')`)
            window.location.href = window.location.href 
            + '?isrecording=true&userId=97d25859-64ac-447d-847c-24efd61776e5&ticketCreation=true'
        }
      }}>
        <div className="text-gray-600 font-bold text-md opacity-0 transition-opacity duration-300">
          {'Create Your Own'}
        </div>
        <div className="rounded-full">
          <img
            src={recordIcon}
            alt="Your image"
            className="rounded-full"
            style={{ width: '100px', height: '100px' }}
          />
        </div>
      </div> */}


    </div>
  )
}

export default App
