/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState, useRef, useEffect } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { ChevronDownIcon, PlayIcon } from '@heroicons/react/solid'
import { classNames } from '../utils/functions'
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import youtube from '../assets/youtube.svg'
import linkedin from '../assets/linkedin.svg'
import { MailIcon } from '@heroicons/react/outline'
import Modal from '../components/Modal'
import { Mixpanel } from '../mixPanel/mixPanel'
import SVG from './SVG'

const integrationIcons = {
  zendesk: `<svg xmlns="http://www.w3.org/2000/svg" class='h-4 w-4 fill-white' width="52.33" height="39.662" viewBox="0 0 52.33 39.662">
  <path id="Icon_simple-zendesk" data-name="Icon simple-zendesk" d="M24.17,44.019H0L24.17,14.839V44.019Zm28.16,0H28.16a12.085,12.085,0,1,1,24.17,0ZM28.16,33.545V4.358H52.33ZM24.17,4.358A12.085,12.085,0,1,1,0,4.362H24.17v0Z" transform="translate(0 -4.357)"/>
</svg>`,
intercom: `<svg xmlns="http://www.w3.org/2000/svg" class='h-4 w-4 fill-white' width="45.375" height="45.375" viewBox="0 0 45.375 45.375">
<path id="Icon_awesome-intercom" data-name="Icon awesome-intercom" d="M39.7,2.25H5.672A5.676,5.676,0,0,0,0,7.922V41.953a5.676,5.676,0,0,0,5.672,5.672H39.7a5.676,5.676,0,0,0,5.672-5.672V7.922A5.676,5.676,0,0,0,39.7,2.25ZM28.734,10.565a1.514,1.514,0,0,1,3.028,0V30.772a1.514,1.514,0,0,1-3.028,0Zm-7.556-.76a1.514,1.514,0,0,1,3.028,0V31.734a1.514,1.514,0,0,1-3.028,0Zm-7.566.76a1.514,1.514,0,0,1,3.028,0V30.772a1.514,1.514,0,0,1-3.028,0ZM6.047,13.594a1.514,1.514,0,0,1,3.028,0V27.2a1.514,1.514,0,0,1-3.028,0v-13.6ZM38.8,36.666c-7.373,6.381-24.48,6.624-32.218,0a1.512,1.512,0,0,1,1.965-2.3c6.675,5.6,21.887,5.459,28.289,0a1.512,1.512,0,0,1,1.965,2.3Zm.527-9.47a1.514,1.514,0,0,1-3.028,0v-13.6a1.514,1.514,0,0,1,3.028,0Z" transform="translate(0 -2.25)"/>
</svg>`
}

interface FlyoutMenuProps {
  children: any
  name: string
  twoSides: boolean
  withMedia: boolean
  withIntegration: boolean
}

const FlyoutMenu: React.FC<FlyoutMenuProps> = ({ children, name, twoSides, withMedia, withIntegration }) => {
  const [isOpen, setOpen] = useState(false)
  const recordModeHref = `${process.env.REACT_APP_DASHBOARD}login?playground=true`

  const buttonRef = useRef<HTMLButtonElement>(null)
  const [openState, setOpenState] = useState(false)

  let timeout: any
  const timeoutDuration = 400

  const toggleMenu = (open: boolean) => {
    // log the current open state in React (toggle open state)
    setOpenState((openState) => !openState)
    // toggle the menu by clicking on buttonRef
    buttonRef?.current?.click() // eslint-disable-line
  }

  const onHover = (open: boolean, action: string) => {
    // if the modal is currently closed, we need to open it
    // OR
    // if the modal is currently open, we need to close it
    if (
      (!open && !openState && action === 'onMouseEnter') ||
      (open && openState && action === 'onMouseLeave')
    ) {
      // clear the old timeout, if any
      clearTimeout(timeout)
      // open the modal after a timeout
      timeout = setTimeout(() => toggleMenu(open), timeoutDuration)
    }
    // else: don't click!
  }

  const handleClick = (open: boolean) => {
    setOpenState(!open) // toggle open state in React state
    clearTimeout(timeout) // stop the hover timer if it's running
  }

  const handleClickOutside = (event: any) => {
    if (buttonRef.current && !buttonRef.current.contains(event.target)) {
      event.stopPropagation()
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  })

  return (
    <div>
      <Popover className="relative">
        {({ open, close }) => (
          <div
          // onMouseEnter={() => onHover(open, "onMouseEnter")}
          // onMouseLeave={() => onHover(open, "onMouseLeave")}
          >
            <Popover.Button
              ref={buttonRef}
              className={classNames(
                open ? 'text-tuto-primary' : 'font-light ',
                'group inline-flex items-center rounded-md  text-base font-medium hover:text-tuto-primary focus:outline-none'
              )}
              onClick={() => {
                Mixpanel.track(`Clicked on ${name}`, {"location": "navigation"})
                handleClick(open)
              }}
            >
              <span>{name}</span>
              <ChevronDownIcon
                className={classNames(
                  open ? 'text-tuto-primary' : 'text-gray-400',
                  'ml-2 h-5 w-5 transition duration-150 ease-in-out group-hover:text-tuto-primary'
                )}
                aria-hidden="true"
              />
            </Popover.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className={`absolute left-1/2 z-10 mt-3 w-screen max-w-md -translate-x-1/2 transform px-2 sm:px-0 ${twoSides ? 'lg:max-w-2xl' : 'lg:max-w-xs'} `}>
                <div className={`overflow-hidden  grid ${twoSides ? 'grid-cols-2' : ''} rounded-lg     `}>
                  <div className="relative grid gap-6  px-5 py-6 sm:gap-8 sm:p-4 lg:grid-cols-1 glass-effect bg-white bg-opacity-20">
                    <div className="blue_circle"></div>
                    {children.map((item: any) =>
                      item.internalLink ? (
                        item.path.includes('#') ? (
                          <HashLink
                            key={item.name}
                            to={item.path}
                            className="-m-3 flex items-start rounded-lg p-3 transition duration-150 ease-in-out hover:bg-white hover:bg-opacity-50"
                          >
                            <div
                              className={`flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-md ${item.background}  sm:h-12 sm:w-12`}
                            >
                              <item.icon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </div>
                            <div className="ml-4">
                              <p className="text-base font-medium ">
                                {item.name}
                              </p>
                              <p className="mt-1 text-sm ">
                                {item.description}
                              </p>
                            </div>
                          </HashLink>
                        ) : (
                          <Link
                            key={item.name}
                            to={item.path}
                            className="-m-3 flex items-start rounded-lg p-3 transition duration-150 ease-in-out hover:bg-white hover:bg-opacity-50"
                            onClick={() => {
                              Mixpanel.track(
                                `Clicked on ${item.name}`,
                                {"location": " navigation"}
                              )
                              close()
                            }}
                          >
                            <div
                              className={`flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-md ${item.background}  sm:h-12 sm:w-12`}
                            >
                              <item.icon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </div>
                            <div className="ml-4">
                              <p className="text-base font-medium ">
                                {item.name}
                              </p>
                              <p className="mt-1 text-sm ">
                                {item.description}
                              </p>
                            </div>
                          </Link>
                        )
                      ) : (
                        <a
                          key={item.name}
                          href={item.path}
                          className="-m-3 flex items-start rounded-lg p-3 transition duration-150 ease-in-out hover:bg-gray-50"
                        >
                          <div
                            className={`flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-md ${item.background} text-white sm:h-12 sm:w-12`}
                          >
                            <item.icon className="h-6 w-6" aria-hidden="true" />
                          </div>
                          <div className="ml-4">
                            <p className="text-base font-medium text-gray-900">
                              {item.name}
                            </p>
                            <p className="mt-1 text-sm text-gray-500">
                              {item.description}
                            </p>
                          </div>
                        </a>
                      )
                    )}
                    {withMedia && 
                    
                    <div className='pt-4 border-t flex items-center'>
                       <a
                        target={'_blank'}
                        className="p-1 rounded-full bg-tuto-third mr-2"
                        href="https://www.linkedin.com/company/tutoit/"
                        onClick={() => {
                          Mixpanel.track(
                            'Clicked on linkedin',
                            { location: 'flyout menu' }
                          )
                        }}
                      >
                        <img className="w-5 h-5" src={linkedin} />
                      </a>
                      <a
                        className="p-1 rounded-full bg-tuto-third mr-2"
                        href="mailto:support@torecord.it"
                        onClick={() => {
                          Mixpanel.track(
                            'Clicked on mail',
                            { location: 'flyout menu' }
                          )
                        }}
                      >
                        <MailIcon className="w-5 h-5" />
                      </a>
                      <a
                        target={'_blank'}
                        className="p-1 rounded-full bg-tuto-third"
                        href="https://www.youtube.com/channel/UCXIvT0oqvAnSY_2_Z4IkiFA"
                        onClick={() => {
                          Mixpanel.track(
                            'Clicked on mail',
                            { location: 'flyout menu' }
                          )
                        }}
                      >
                        <img className="w-5 h-5" src={youtube} />
                      </a> 
                       {/* <div className='flex gap-2'>
                        <Link to='/integrations/zendesk' className='bg-tuto-fourth p-2 rounded relative cursor-pointer'>
                          <SVG svgCode={integrationIcons.zendesk} />
                        </Link>
                        
                        <Link to='/integrations/intercom' className='bg-tuto-fourth p-2 rounded relative cursor-pointer'>
                        <SVG svgCode={integrationIcons.intercom} />
                        </Link>
                      
                      </div> */}
                    </div>
                    }
                    {withIntegration && 
                    
                    <div className='border-t pt-4 flex items-start gap-2'>
                        <Link to='/integrations/zendesk' className='bg-tuto-fourth p-2 rounded relative cursor-pointer'>
                          <SVG svgCode={integrationIcons.zendesk} />
                        </Link>
                        <Link to='/integrations/intercom' className='bg-tuto-fourth p-2 rounded relative cursor-pointer'>
                        <SVG svgCode={integrationIcons.intercom} />
                        </Link>
                    </div>
                    }
                  </div>
                  {twoSides && 
                  
                  <div className="bg-gray-100 p-4 flex flex-col justify-between relative">
                    <div className="purple_circle"></div>
                    <div>
                      <h1 className="text-xl font-semibold mb-4">
                        See It in Action
                      </h1>
                      <p className="text-sm text-gray-500 mb-4">
                        Use our playground mode to evaluate the product. We give
                        you full control
                      </p>
                      <button
                        onClick={() => {
                          Mixpanel.track(
                            `Clicked on Playground`,
                            {location: 'flyout menu'}
                          )

                          setOpen(true)
                        }}
                        className="button shadow-xl font-semibold"
                      >
                        <PlayIcon className="mr-2 h-5 w-5" />
                        Live Demo
                      </button>
                    </div>
                    <div className="pt-4 flex flex-col items-start gap-2">
                      <p className='text-base font-medium'>Integrations</p>
                      <div className='flex gap-2'>
                        <Link to='/integrations/zendesk' className='bg-tuto-fourth p-2 rounded relative cursor-pointer'>
                          <SVG svgCode={integrationIcons.zendesk} />
                        </Link>
                        {/* <div className='bg-tuto-fourth p-2 rounded relative cursor-pointer'>
                        <span className='absolute text-green-400 right-1 -top-1'>*</span>
                          <PlayIcon className='w-6 h-6 text-white' />
                        </div> */}
                        <Link to='/integrations/intercom' className='bg-tuto-fourth p-2 rounded relative cursor-pointer'>
                        <SVG svgCode={integrationIcons.intercom} />
                        </Link>
                        {/* <div className='bg-tuto-fourth p-2 rounded relative cursor-pointer'>
                          <span className='absolute text-green-400 right-1 -top-1'>*</span>
                          <PlayIcon className='w-6 h-6 text-white' />
                        </div> */}
                      </div>
                      {/* <a
                        target={'_blank'}
                        className="p-1 rounded-full bg-tuto-secondary mr-2"
                        href="https://www.linkedin.com/company/tutoit/"
                        onClick={() => {
                          Mixpanel.track(
                            'Clicked on linkedin',
                            { location: 'flyout menu' }
                          )
                        }}
                      >
                        <img className="w-5 h-5" src={linkedin} />
                      </a>
                      <a
                        className="p-1 rounded-full bg-tuto-secondary mr-2"
                        href="mailto:support@torecord.it"
                        onClick={() => {
                          Mixpanel.track(
                            'Clicked on mail',
                            { location: 'flyout menu' }
                          )
                        }}
                      >
                        <MailIcon className="w-5 h-5" />
                      </a>
                      <a
                        target={'_blank'}
                        className="p-1 rounded-full bg-tuto-secondary"
                        href="https://www.youtube.com/channel/UCXIvT0oqvAnSY_2_Z4IkiFA"
                        onClick={() => {
                          Mixpanel.track(
                            'Clicked on mail',
                            { location: 'flyout menu' }
                          )
                        }}
                      >
                        <img className="w-5 h-5" src={youtube} />
                      </a> */}
                    </div>
                  </div>
                  }
                  {/* <div className="bg-gray-50 p-5 sm:p-8">
                  <a
                    href="#"
                    className="-m-3 flow-root rounded-md p-3 transition duration-150 ease-in-out hover:bg-gray-100"
                  >
                    <span className="flex items-center">
                      <span className="text-base font-medium text-gray-900">Enterprise</span>
                      <span className="ml-3 inline-flex items-center rounded-full bg-indigo-100 px-3 py-0.5 text-xs font-medium leading-5 text-indigo-800">
                        New
                      </span>
                    </span>
                    <span className="mt-1 block text-sm text-gray-500">
                      Empower your entire team with even more advanced tools.
                    </span>
                  </a>
                </div> */}
                </div>
              </Popover.Panel>
            </Transition>
          </div>
        )}
      </Popover>
      <Modal
        isOpen={isOpen}
        setOpen={setOpen}
        isVideo={false}
        href={recordModeHref}
      />
    </div>
  )
}

export default FlyoutMenu
