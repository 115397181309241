import React, { useEffect, useState } from 'react'
import { CheckIcon } from '@heroicons/react/solid'
import './pricing.css'
import Layout from '../layouts/Layout'
import { Mixpanel } from '../mixPanel/mixPanel'
import Logos from '../components/logos'
import FooterMenu from '../components/FooterMenu'

const tiers = [
  {
    name: 'Free',
    max_seats: 1,
    max_records: 20,
    max_sessions: 500,
    max_data_storage: 7,
    price: 0,
    record_feed: true,
    record_investigation: true,
    ticket_integration: true,
    window_object: false,
    record_ai: false
  },
  {
    name: 'Starter',
    max_seats: 5,
    max_records: 300,
    max_sessions: '5,000',
    max_data_storage: 14,
    price: 2400,
    record_feed: true,
    record_investigation: true,
    ticket_integration: true,
    window_object: false,
    record_ai: false
  },
  {
    name: 'Ultimate',
    max_seats: 15,
    max_records: '2,500',
    max_sessions: '10,000',
    max_data_storage: 30,
    price: 9600,
    record_feed: true,
    record_investigation: true,
    ticket_integration: true,
    window_object: true,
    record_ai: false
  },
  {
    name: 'Businesses',
    max_seats: 30,
    max_records: '6,000',
    max_sessions: '100,000',
    max_data_storage: 90,
    price: 21000,
    record_feed: true,
    record_investigation: true,
    window_object: true,
    ticket_integration: true,
    record_ai: true
  }
]

interface PricingProps {
  plans?: any
  tiers?: any
}

const Pricing: React.FC<PricingProps> = ({ plans }) => {
  const [annualBilling, setAnnualBilling] = useState(true)
  // const [activePlans, setActivePlans] = useState([])

  // useEffect(() => {
  //   if (plans.year) {
  //     setActivePlans(plans.year)
  //   }
  // }, [])
  // useEffect(() => {
  //   changePlans()
  // }, [annualBilling])

  // const changePlans = () => {
  //   if (annualBilling) {
  //     setActivePlans(plans.year)
  //   } else {
  //     setActivePlans(plans.month)
  //   }
  // }

  useEffect(() => {
    Mixpanel.register({ Page: "Pricing" })
    Mixpanel.track('Pricing Page View', {})
  }, [])

  const featuresIncluded = (tier: any) => {
    return (
      <ul role="list" className="mt-6 space-y-4">
        <li className="flex space-x-3">
          <CheckIcon
            className="h-5 w-5 flex-shrink-0 text-green-500"
            aria-hidden="true"
          />
          <span className="text-sm ">{tier.max_seats} seat{tier.max_seats == 1 ? '' : 's'}</span>
        </li>
        <li className="flex space-x-3">
          <CheckIcon
            className="h-5 w-5 flex-shrink-0 text-green-500"
            aria-hidden="true"
          />
          <span className="text-sm ">
            {tier.max_records} Records per month
          </span>
        </li>
        <li className="flex space-x-3">
          <CheckIcon
            className="h-5 w-5 flex-shrink-0 text-green-500"
            aria-hidden="true"
          />
          <span className="text-sm ">
            {tier.max_sessions} Sessions per month
          </span>
        </li>
        <li className="flex space-x-3">
          <CheckIcon
            className="h-5 w-5 flex-shrink-0 text-green-500"
            aria-hidden="true"
          />
          <span className="text-sm ">
            {tier.max_data_storage} days Data storage limit
          </span>
        </li>
        {tier.record_feed && (
          <li className="flex space-x-3">
            <CheckIcon
              className="h-5 w-5 flex-shrink-0 text-green-500"
              aria-hidden="true"
            />
            <span className="text-sm ">Record Feed</span>
          </li>
        )}
        {tier.record_investigation && (
          <li className="flex space-x-3">
            <CheckIcon
              className="h-5 w-5 flex-shrink-0 text-green-500"
              aria-hidden="true"
            />
            <span className="text-sm ">Record Investigation</span>
          </li>
        )}
        {tier.ticket_integration && (
          <li className="flex space-x-3">
            <CheckIcon
              className="h-5 w-5 flex-shrink-0 text-green-500"
              aria-hidden="true"
            />
            <span className="text-sm ">Ticketing System Integration</span>
          </li>
        )}
        {tier.window_object && (
          <li className="flex space-x-3">
            <CheckIcon
              className="h-5 w-5 flex-shrink-0 text-green-500"
              aria-hidden="true"
            />
            <span className="text-sm ">Window Object Capturing</span>
          </li>
        )}
        {tier.record_ai && (
          <li className="flex space-x-3">
            <CheckIcon
              className="h-5 w-5 flex-shrink-0 text-green-500"
              aria-hidden="true"
            />
            <span className="text-sm ">Record AI</span>
          </li>
        )}
      </ul>
    )
  }
  // window.onPaddleSuccess = function (e: any) {
  //   //window.location.href = '/purchase';
  // }
  // window.onPaddleClose = function (e: any) {}

  return (
    <Layout withCircles={true}>
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:p-8">
        <div className="sm:flex sm:flex-col ">
          <h1 className="text-5xl text-center font-bold tracking-tight  ">
            Pricing Plans
          </h1>
          <p className="mt-5 text-xl text-center ">
          Upgrade your CX team with Record - faster resolution times are just few clicks away.
          </p>
          <div className="relative mt-6 flex self-center rounded-lg glass-effect bg-white bg-opacity-20 p-0.5 sm:mt-8">
            <button
              onClick={() => setAnnualBilling(false)}
              type="button"
              className={`relative w-1/2 whitespace-nowrap rounded-md  ${
                annualBilling ? '' : 'glass-effect bg-white bg-opacity-20 shadow-sm'
              } py-2 text-sm font-medium   focus:z-10 focus:outline-none  sm:w-auto sm:px-8`}
            >
              Monthly billing
            </button>
            <button
              onClick={() => setAnnualBilling(true)}
              type="button"
              className={`relative ml-0.5 w-1/2 whitespace-nowrap rounded-md ${
                annualBilling ? 'glass-effect bg-white bg-opacity-20 shadow-sm' : ''
              } py-2 text-sm font-medium  focus:z-10 focus:outline-none sm:w-auto sm:px-8`}
            >
              Annual billing
            </button>
          </div>
        </div>
        <div className="mt-12 space-y-4 sm:mt-16 sm:grid sm:grid-cols-2 sm:gap-6 sm:space-y-0 lg:mx-auto lg:max-w-4xl xl:mx-0 xl:max-w-none xl:grid-cols-4">
          {tiers.map((tier: any) => (
            <div
              key={tier.name}
              className={`divide-y border ${
                false ? 'border-tuto-purple' : 'border-gray-200'
              } divide-gray-200 rounded-lg glass-effect bg-white bg-opacity-20 shadow-sm relative overflow-hidden`}
            >
              {tier.name.toLowerCase() == 'ultimate' && (
                <div className="ribbon">Most Popular</div>
              )}

              <div className="p-6">
                <h2 className="text-lg font-medium leading-6  capitalize">
                  {tier.name}
                </h2>
                {/* <p className="mt-4 text-sm text-gray-500">{tier.description}</p> */}
                <p className="mt-8">
                  <span className="text-4xl font-bold tracking-tight ">
                    $
                    {annualBilling
                      ? Math.round((tier.price / 12) / tier.max_seats )
                      : Math.round((tier.price / 10) / tier.max_seats)}
                  </span>{' '}
                  <span className="text-base font-medium ">
                    /seat per month
                  </span>
                </p>

                <button
                  onClick={() => {
                    Mixpanel.track('user clicked on start for free', {
                      from: tier.name
                    })
                    window.open(
                      `${process.env.REACT_APP_DASHBOARD}/signup`,
                      'blank'
                    )
                  }}
                  className="mt-8 block w-full rounded-md border capitalize border-normal-black bg-normal-black py-2 text-center text-sm font-semibold text-white hover:bg-gray-900"
                >
                  Try For Free
                </button>
                {tier.price !== 0 ?
                
                <div className='text-sm  mt-2'>14-Day trial, no credit card needed</div>
                :<div className='text-sm text-transparent mt-2'>14-Day trial, no credit card needed</div>
                }
              </div>
              <div className="px-6 pt-6 pb-8">
                <h3 className="text-sm font-medium ">
                  What's included
                </h3>
                <ul role="list" className="mt-6 space-y-4">
                  {featuresIncluded(tier)}
                </ul>
              </div>
            </div>
          ))}
        </div>
        <div className='mt-12'>
          <Logos />
        </div>
        <FooterMenu/>
      </div>
    </Layout>
  )
}

export default Pricing
