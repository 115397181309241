import { useEffect } from 'react'
import Layout from '../layouts/Layout'
import { Mixpanel } from '../mixPanel/mixPanel'
import { useParams } from 'react-router-dom'


interface DocsProps {
  source: string,
  type: string
}

const Docs: React.FC<DocsProps> = ({source, type}) => {
  const { blog_slug } = useParams<{ blog_slug: string }>();
  console.log(type)

  useEffect(() => {
    Mixpanel.track(`${type} Page Viewed`, {})
  }, [])

  return (
    <Layout withCircles={false}>
      <iframe
        className="w-full h-screen"
        src={ blog_slug ? `${source}/${blog_slug}` : source }
      />
    </Layout>
  )
}

export default Docs
