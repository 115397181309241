import React from 'react'

interface ReasourceProps {
  title: string
  imgSrc: string
  description?: string
  type: string
}

const Resource: React.FC<ReasourceProps> = ({ title, imgSrc, type }) => { 
  return (
    <div className="mb-8 p-2 md:mb-2 md:mr-6 max-w-xs transition duration-300 hover:scale-105 cursor-pointer">
      <div className="mb-4 rounded shadow-lg h-52">

      <img src={imgSrc} className='resource-img'/>
      </div>
      <div className='mb-4'>
        <span className={`py-1 px-2 rounded ${type === 'Webinar' ? 'bg-tuto-fifth' : 'bg-tuto-secondary'}`}>{type}</span>
      </div>
      <div>{title}</div>
    </div>
  )
}

export default Resource
