import Layout from '../layouts/Layout'
import { SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { CheckCircleIcon } from '@heroicons/react/outline'
import { Mixpanel } from '../mixPanel/mixPanel'
import { useEffect, useState } from 'react'
import { resources } from '../content/webinars'
import { useMatch } from 'react-router-dom'

interface IFormInputs {
  email: string
  fullName: string
}

const schema = yup.object().shape({
  email: yup
    .string()
    .email()
    .matches(
      /(?=^((?!@gmail.com).)*$)(?=^((?!@yahoo.com).)*$)/,
      'email must be a work email'
    )
    .required(),
  fullName: yup.string().required()
})

const SingleResource = () => {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors, touchedFields }
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    mode: 'all'
  })

  const resourceSlug: any = useMatch('/resources/webinars/:slug')

  const [loading, setLoading] = useState(false)
  const [formSent, setFormSent] = useState(false)
  const [resource, setResource] = useState<any>('')

  useEffect(() => {
    Mixpanel.track('Single Resource Page View', {
      'resource id': resourceSlug.params.id
    })
    const resourceToFind = resources.find(
      (resource) => resource.slug === resourceSlug.params.slug
    )

    setResource(resourceToFind)
  }, [])

  const formSubmitHandler: SubmitHandler<IFormInputs> = async (
    data: IFormInputs
  ) => {
    const body = {
      email: data.email,
      name: data.fullName,
      link: resource.link,
      mailDesc: resource.mailDesc,
      mailDescTitle: resource.mailDescTitle,
      utm: 'resources',
      is_send_webinar: resource.type === 'Webinar'
    }
    setLoading(true)
    Mixpanel.track('Clicked on Watch now', {
      title: resource.title,
      email: data.email,
      name: data.fullName
    })

    try {
      const res = await fetch(
        `${process.env.REACT_APP_API}/earlySignUp/addLead`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          method: 'POST',
          body: JSON.stringify(body)
        }
      )

      const parsedRes = await res.json()
      if (parsedRes.success) {
        setLoading(false)
        setFormSent(true)
      }
    } catch (error) {
      setLoading(false)
      reset()
    }
  }

  return (
    <Layout withCircles={false}>
      <div className="flex flex-col md:flex-row mt-8 container mx-auto mb-4">
        <div className="mb-12 md:mb-0 flex-grow">
          <img
            className="max-w-xs mx-auto md:max-w-xl shadow-lg rounded-lg"
            src={resource.imgSrc}
          />
        </div>
        <div className="flex-grow p-4 md:p-0 md:pl-16">
          <span className="py-1 px-2 rounded bg-tuto-fifth">
            {resource.type}
          </span>

          <div>
            <h1 className="text-3xl my-4">{resource.title}</h1>
          </div>

          <div>
            <form
              onSubmit={handleSubmit(formSubmitHandler)}
              className="flex flex-col"
              id='resources'
            >
              <div className="relative w-full">
                <input
                  type="text"
                  {...register('fullName')}
                  className="border-0 focus:ring-0 w-full border-b-2 p-2 mb-6"
                  placeholder="Full Name *"
                />
                <span className="text-red-500 absolute bottom-0 left-0">
                  {errors.fullName?.message}
                </span>
              </div>
              <div className="relative w-full">
                <input
                  type="email"
                  {...register('email')}
                  className="border-0 focus:ring-0 w-full  border-b-2 p-2 mb-6"
                  placeholder="Work Email *"
                />
                <span className="text-red-500 absolute bottom-0 left-0">
                  {errors.email?.message}
                </span>
              </div>
              {!formSent ? (
                <button className="tuto-button my-4">Watch Now</button>
              ) : (
                <div className="p-4 bg-green-400 rounded-lg mb-4 flex items-center">
                  <CheckCircleIcon className="h-5 w-5 mr-4" /> Check your mail
                  for the full webinar details.
                </div>
              )}
            </form>
          </div>
          <div dangerouslySetInnerHTML={{ __html: resource.description }}></div>
        </div>
      </div>
    </Layout>
  )
}

export default SingleResource
