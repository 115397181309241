export const features = [
  {
    name: 'Session Recordings',
    color: 'bg-tuto-light-blue',
    icon: `<svg xmlns="http://www.w3.org/2000/svg" width="57.257" height="57.257" viewBox="0 0 57.257 57.257">
    <path id="Icon_material-collections" data-name="Icon material-collections" d="M60.257,43.08V8.726A5.743,5.743,0,0,0,54.531,3H20.177a5.743,5.743,0,0,0-5.726,5.726V43.08a5.743,5.743,0,0,0,5.726,5.726H54.531A5.743,5.743,0,0,0,60.257,43.08ZM28.766,31.628l5.812,7.758,8.5-10.621L54.531,43.08H20.177ZM3,14.451v40.08a5.743,5.743,0,0,0,5.726,5.726h40.08V54.531H8.726V14.451Z" transform="translate(-3 -3)" fill="#fffcfc"/>
  </svg>`,
    description: ' Identify pain points, provide more effective support',
    video: 'https://assets.torecord.it/website/sessions-full-video.mp4',
    route: 'sessions',
    tabs: [
      {
        title: 'Identify pain points',
        content:
          'User sessions are a valuable source of information for SaaS companies looking to improve their customer support operations. By analyzing user sessions, technical support teams can gain valuable insights into user behavior, identify pain points, and provide more effective support.',
        img: 'https://assets.torecord.it/website/pain-points-img.png',
        sideImg: false,
        icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
        <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
      </svg>`,
        specialClass: 'max-w-xl',
        video: null
      },
      {
        title: 'Grasp User Behavior',
        content:
          'User sessions provide a window into how users interact with your software, allowing you to identify pain points and areas for improvement.',
        img: 'https://assets.torecord.it/website/behaviors-img.png',
        sideImg: false,
        icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
        <path stroke-linecap="round" stroke-linejoin="round" d="M15.042 21.672L13.684 16.6m0 0l-2.51 2.225.569-9.47 5.227 7.917-3.286-.672zM12 2.25V4.5m5.834.166l-1.591 1.591M20.25 10.5H18M7.757 14.743l-1.59 1.59M6 10.5H3.75m4.007-4.243l-1.59-1.59" />
      </svg>`,
        specialClass: 'max-w-xl shadow-small rounded-xl',
        video: null
      },
      {
        title: 'Personalize Support',
        content:
          `By analyzing user sessions, support teams can gain insights into a user's specific experience, allowing them to provide more personalized and effective support.`,
        img: 'https://assets.torecord.it/capture-add-info.png',
        sideImg: true,
        icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
        <path stroke-linecap="round" stroke-linejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
      </svg>`,
        specialClass: 'max-w-xs shadow-small rounded-xl',
        video: null
      },
      {
        title: 'Resolve Efficiently',
        content:
          'User session data can help technical support teams identify the root cause of issues more quickly, leading to faster and more efficient issue resolution.',
        img: 'https://assets.torecord.it/capture-network-console.png',
        sideImg: true,
        icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
        <path stroke-linecap="round" stroke-linejoin="round" d="M11.42 15.17L17.25 21A2.652 2.652 0 0021 17.25l-5.877-5.877M11.42 15.17l2.496-3.03c.317-.384.74-.626 1.208-.766M11.42 15.17l-4.655 5.653a2.548 2.548 0 11-3.586-3.586l6.837-5.63m5.108-.233c.55-.164 1.163-.188 1.743-.14a4.5 4.5 0 004.486-6.336l-3.276 3.277a3.004 3.004 0 01-2.25-2.25l3.276-3.276a4.5 4.5 0 00-6.336 4.486c.091 1.076-.071 2.264-.904 2.95l-.102.085m-1.745 1.437L5.909 7.5H4.5L2.25 3.75l1.5-1.5L7.5 4.5v1.409l4.26 4.26m-1.745 1.437l1.745-1.437m6.615 8.206L15.75 15.75M4.867 19.125h.008v.008h-.008v-.008z" />
      </svg>`,
        specialClass: 'max-w-xs shadow-small rounded-xl',
        video: null
      },
      {
        title: 'Boost Satisfaction',
        content:
          'By providing personalized, effective support and resolving issues quickly, user session analysis can help improve overall customer satisfaction and loyalty.',
        img: 'https://assets.torecord.it/website/satisfaction-img.png',
        sideImg: false,
        icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
        <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z" />
      </svg>`,
        specialClass: 'max-w-md shadow-small rounded-xl',
        video: null
      },
      {
        title: 'Everything You Need',
        content:
          'Visual recording of the session, user and device information, console log, and network information. User window attribute and much more.',
        img: 'https://assets.torecord.it/capture-custom-data.png',
        sideImg: false,
        icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
        <path stroke-linecap="round" stroke-linejoin="round" d="M9 8.25H7.5a2.25 2.25 0 00-2.25 2.25v9a2.25 2.25 0 002.25 2.25h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25H15M9 12l3 3m0 0l3-3m-3 3V2.25" />
      </svg>`,
        specialClass: 'max-w-md shadow-small rounded-xl',
        video: null
      }
    ]
  },
  {
    name: 'Record Investigation',
    color: 'bg-tuto-secondary',
    icon: `<svg xmlns="http://www.w3.org/2000/svg" width="63.239" height="58.723" viewBox="0 0 63.239 58.723">
    <g id="Icon_ionic-md-analytics" data-name="Icon ionic-md-analytics" transform="translate(-2.25 -3.375)">
      <path id="Path_432" data-name="Path 432" d="M51.278,13.132,38.971,31.958A6.7,6.7,0,0,1,40.5,36.234a6.774,6.774,0,1,1-13.548,0,7.423,7.423,0,0,1,.113-1.256L18.931,30.25a6.732,6.732,0,0,1-4.7,1.891,6.812,6.812,0,0,1-3.331-.875l-8.651,8.1V47.44a6.041,6.041,0,0,0,6.026,6.026H59.463a6.041,6.041,0,0,0,6.026-6.026v-30l-8.27-5.49a6.769,6.769,0,0,1-5.941,1.185Z" transform="translate(0 8.632)" fill="#fff"/>
      <path id="Path_433" data-name="Path 433" d="M14.246,27.226A6.776,6.776,0,0,1,21.02,34c0,.3-.028.593-.056.875l8.482,4.742a6.714,6.714,0,0,1,4.29-1.524,7.088,7.088,0,0,1,1.468.155L47.538,19.139a6.7,6.7,0,0,1-1.256-3.923,6.774,6.774,0,1,1,13.548,0,7.362,7.362,0,0,1-.183,1.595l5.829,3.9V9.4a6.041,6.041,0,0,0-6.026-6.026H8.276A6.041,6.041,0,0,0,2.25,9.4V41.762L7.9,36.357a6.774,6.774,0,0,1,6.351-9.131Z" transform="translate(0 0)" fill="#fff"/>
    </g>
    </svg>`,
    description: 'Find the issues before the next ticket',
    video: 'https://assets.torecord.it/website/investigation-full-video.mp4',
    route: 'investigation',
    tabs: [
      {
        title: 'Slice and Dice',
        content:
          'Define your target group with precision using complex logic and deep data analysis. This enables you to refine your customer interactions and make data-driven decisions based on precise customer segmentation.',
        img: 'https://assets.torecord.it/website/proactive-img.png',
        sideImg: false,
        icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class='h-6 w-6'  width="30" height="30" viewBox="0 0 30 30">
    <path id="Icon_material-insert-emoticon" data-name="Icon material-insert-emoticon" d="M17.985,3A15,15,0,1,0,33,18,14.993,14.993,0,0,0,17.985,3ZM18,30A12,12,0,1,1,30,18,12,12,0,0,1,18,30Zm5.25-13.5A2.25,2.25,0,1,0,21,14.25,2.247,2.247,0,0,0,23.25,16.5Zm-10.5,0a2.25,2.25,0,1,0-2.25-2.25A2.247,2.247,0,0,0,12.75,16.5ZM18,26.25A8.244,8.244,0,0,0,25.665,21H10.335A8.244,8.244,0,0,0,18,26.25Z" transform="translate(-3 -3)"/>
  </svg>`,
        specialClass: 'max-w-md shadow-small rounded-xl p-4',
        video: null
      },
      {
        title: 'Find Root Causes',
        content:
          'By examining the bigger picture, effortlessly pinpoint the core reasons for issues and proactively detect and monitor concealed bugs.',
        img: 'https://assets.torecord.it/website/root-cause-img.png',
        sideImg: false,
        icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class='h-6 w-6' width="27.5" height="22" viewBox="0 0 27.5 22">
        <path id="Icon_awesome-tags" data-name="Icon awesome-tags" d="M21.4,9.708,12.292.6A2.062,2.062,0,0,0,10.833,0H2.063A2.062,2.062,0,0,0,0,2.063v8.771a2.062,2.062,0,0,0,.6,1.458l9.1,9.1a2.062,2.062,0,0,0,2.917,0L21.4,12.625a2.062,2.062,0,0,0,0-2.917ZM4.813,6.875A2.063,2.063,0,1,1,6.875,4.813,2.062,2.062,0,0,1,4.813,6.875ZM26.9,12.625,18.125,21.4a2.062,2.062,0,0,1-2.917,0l-.015-.015L22.672,13.9a3.867,3.867,0,0,0,0-5.469L14.24,0h2.093a2.062,2.062,0,0,1,1.458.6l9.1,9.1a2.062,2.062,0,0,1,0,2.917Z"/>
      </svg>`,
        specialClass: 'max-w-md shadow-small rounded-xl p-4',
        video: null
      },
      {
        title: 'Create Investigations',
        content:
          'An investigation is a collection of intentional user screen recordings and user session recordings that were collected by Record in the background. You can easily open an investigation based on different parameters to narrow down the search exactly for what you need.',
        img: 'https://assets.torecord.it/website/create-investigation-img.png',
        sideImg: false,
        icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class='h-6 w-6' width="26.25" height="26.25" viewBox="0 0 26.25 26.25">
    <path id="Icon_awesome-thumbs-up" data-name="Icon awesome-thumbs-up" d="M5.332,11.484H1.23A1.23,1.23,0,0,0,0,12.715v12.3a1.23,1.23,0,0,0,1.23,1.23h4.1a1.23,1.23,0,0,0,1.23-1.23v-12.3A1.23,1.23,0,0,0,5.332,11.484ZM3.281,24.2a1.23,1.23,0,1,1,1.23-1.23A1.23,1.23,0,0,1,3.281,24.2ZM19.688,4.176c0,2.175-1.331,3.394-1.706,4.847H23.2A3.063,3.063,0,0,1,26.25,12a3.712,3.712,0,0,1-1,2.522l-.006.006A4.283,4.283,0,0,1,24.77,18.6a4.054,4.054,0,0,1-.84,3.833,2.722,2.722,0,0,1-.315,2.288c-1.046,1.5-3.64,1.525-5.833,1.525h-.146a14.718,14.718,0,0,1-6.13-1.627,8.069,8.069,0,0,0-2.7-.829.615.615,0,0,1-.6-.615V12.219a.615.615,0,0,1,.182-.437c2.031-2.007,2.9-4.132,4.569-5.8a6.209,6.209,0,0,0,1.3-3.02C14.484,2.015,14.961,0,16,0,17.227,0,19.688.41,19.688,4.176Z"/>
  </svg>`,
        specialClass: 'max-w-md shadow-small rounded-xl p-4',
        video: null
      },
      {
        title: 'Tracking & Monitoring',
        content:
          'You can monitor any open investigation, see a graph, and be notified of how many new entries you get each day on your investigation page or directly to your email. You can use it to proactively act on hidden bugs and reach out to users who might be affected by the investigation’s research goal.',
        img: 'https://assets.torecord.it/website/track-and-monitor-img.png',
        sideImg: false,
        icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class='h-6 w-6' width="31.5" height="31.5" viewBox="0 0 31.5 31.5">
    <path id="Icon_awesome-hashtag" data-name="Icon awesome-hashtag" d="M30.984,12.8l.5-2.812A.844.844,0,0,0,30.656,9H25.4l1.028-5.758a.844.844,0,0,0-.831-.992H22.737a.844.844,0,0,0-.831.7L20.825,9H13.89l1.028-5.758a.844.844,0,0,0-.831-.992H11.23a.844.844,0,0,0-.831.7L9.318,9H3.757a.844.844,0,0,0-.831.7l-.5,2.813a.844.844,0,0,0,.831.992h5.26l-1.607,9H1.346a.844.844,0,0,0-.831.7l-.5,2.813A.844.844,0,0,0,.844,27H6.1L5.076,32.758a.844.844,0,0,0,.831.992H8.763a.844.844,0,0,0,.831-.7L10.675,27H17.61l-1.028,5.758a.844.844,0,0,0,.831.992H20.27a.844.844,0,0,0,.831-.7L22.182,27h5.561a.844.844,0,0,0,.831-.7l.5-2.813a.844.844,0,0,0-.831-.992h-5.26l1.607-9h5.561a.844.844,0,0,0,.831-.7Zm-12.57,9.7H11.479l1.607-9h6.935Z" transform="translate(0 -2.25)"/>
  </svg>`,
        specialClass: 'max-w-lg',
        video: null
      },
      {
        title: 'Session Recording',
        content:
          'Use the investigation to unlock the power of user session recording. As it is crucial to customer support as they allow teams to understand customer issues effectively, replicate problems, improve the product or service, and provide effective solutions.',
        img: 'https://assets.torecord.it/website/session-recording-img.png',
        sideImg: false,
        icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class='h-6 w-6' width="27" height="33" viewBox="0 0 27 33">
    <g fill="currentColor" id="Icon_feather-file-text" data-name="Icon feather-file-text" transform="translate(-4.5 -1.5)">
      <path id="Path_427" data-name="Path 427" d="M21,3H9A3,3,0,0,0,6,6V30a3,3,0,0,0,3,3H27a3,3,0,0,0,3-3V12Z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
      <path id="Path_428" data-name="Path 428" d="M21,3v9h9" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
      <path id="Path_429" data-name="Path 429" d="M24,19.5H12" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
      <path id="Path_430" data-name="Path 430" d="M24,25.5H12" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
      <path id="Path_431" data-name="Path 431" d="M15,13.5H12" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
    </g>
  </svg>`,
        specialClass: 'max-w-md shadow-small rounded-xl p-4',
        video: null
      }
    ]
  },
  {
    name: 'Privacy & Security',
    icon: `<svg xmlns="http://www.w3.org/2000/svg" width="62.591" height="76.5" viewBox="0 0 62.591 76.5">
    <path id="Icon_material-security" data-name="Icon material-security" d="M35.8,1.5,4.5,15.409V36.273C4.5,55.572,17.853,73.619,35.8,78c17.943-4.381,31.3-22.428,31.3-41.727V15.409Zm0,38.215H60.137C58.294,54.042,48.731,66.8,35.8,70.8V39.75H11.455V19.93L35.8,9.115Z" transform="translate(-4.5 -1.5)" fill="#fff"/>
  </svg>`,
    color: 'bg-tuto-primary',
    description: 'First.',
    video: 'https://assets.torecord.it/record_mask_and_block.mp4',
    route: 'privacy-security',
    tabs: [
      {
        title: 'GDPR - Compliant Data Processor',
        content:
          'To ensure full compliance with GDPR, all data recorded by Record is securely stored within the European Union. For those who require it, we offer a Data Processing Agreement (DPA). The data is securely stored in the AWS Europe Ireland (eu-west-1) datacenter.',
        img: 'https://assets.torecord.it/privacy-gdpr.png',
        sideImg: false,
        icon: '',
        specialClass: 'w-40',
        video: null
      },
      {
        title: 'Masking & Blocking Customized For Your Product',
        content:
          'In addition to automatically obscuring sensitive information, Record allows you to quickly select and block or mask additional elements within your product',
        img: 'https://assets.torecord.it/privacy-masking.png',
        sideImg: false,
        icon: '',
        specialClass: 'max-w-xl shadow-small rounded-xl p-4',
        video: null
      },
      {
        title: 'Users & Passwords',
        content:
          'Record utilizes Amazon Cognito for user authentication. Amazon Cognito offers secure authentication, authorization, and user management for web and mobile applications. All data within Amazon Cognito is encrypted to meet industry standards and complies with SOC 1-3, PCI DSS, ISO 27001, and is eligible for HIPAA-BAA compliance.',
        img: 'https://assets.torecord.it/privacy-users.png',
        sideImg: false,
        icon: '',
        specialClass: 'max-w-xs shadow-small rounded-xl',
        video: null
      },
      {
        title: 'Application & Script Security',
        content:
          'Record uses SSL connection encryption (certificated by Amazon Web Services) across all our domains.',
        img: 'https://assets.torecord.it/privacy-app-security.png',
        sideImg: false,
        icon: '',
        specialClass: 'w-40',
        video: null
      }
    ]
  },
  {
    name: 'Record Feed',
    color: 'bg-tuto-light-blue',
    icon: `<svg xmlns="http://www.w3.org/2000/svg" width="57.257" height="57.257" viewBox="0 0 57.257 57.257">
    <path id="Icon_material-collections" data-name="Icon material-collections" d="M60.257,43.08V8.726A5.743,5.743,0,0,0,54.531,3H20.177a5.743,5.743,0,0,0-5.726,5.726V43.08a5.743,5.743,0,0,0,5.726,5.726H54.531A5.743,5.743,0,0,0,60.257,43.08ZM28.766,31.628l5.812,7.758,8.5-10.621L54.531,43.08H20.177ZM3,14.451v40.08a5.743,5.743,0,0,0,5.726,5.726h40.08V54.531H8.726V14.451Z" transform="translate(-3 -3)" fill="#fffcfc"/>
  </svg>`,
    description: 'All the data you need in one place',
    video: 'https://assets.torecord.it/short-first-website.mp4',
    route: 'capture',
    tabs: [
      {
        title: 'Issue Reply',
        content:
          'See a visual representation of what happened. the timeline of the issue recording includes every data point we capture so it is easy to jump to the relevant location at the recording at any given time.',
        img: 'https://assets.torecord.it/capture-issue-reply.png',
        sideImg: false,
        icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class='h-6 w-6' width="24" height="30" viewBox="0 0 24 30">
        <path id="Icon_material-replay" data-name="Icon material-replay" d="M18,7.5v-6L10.5,9,18,16.5v-6a9,9,0,1,1-9,9H6a12,12,0,1,0,12-12Z" transform="translate(-6 -1.5)"/>
      </svg>`
      },
      {
        title: 'Transcript',
        content:
          'Sometimes you only want to scan the problem to understand what the user wants. We provide you with the full transcript of what the user said to read or copy and paste relevant parts to share with your team.',
        img: 'https://assets.torecord.it/capture-transcript.png',
        sideImg: false,
        icon: `<svg xmlns="http://www.w3.org/2000/svg" class='h-6 w-6' width="27" height="33" viewBox="0 0 27 33">
    <g id="Icon_feather-file-text" data-name="Icon feather-file-text" transform="translate(-4.5 -1.5)">
      <path id="Path_427" data-name="Path 427" d="M21,3H9A3,3,0,0,0,6,6V30a3,3,0,0,0,3,3H27a3,3,0,0,0,3-3V12Z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
      <path id="Path_428" data-name="Path 428" d="M21,3v9h9" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
      <path id="Path_429" data-name="Path 429" d="M24,19.5H12" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
      <path id="Path_430" data-name="Path 430" d="M24,25.5H12" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
      <path id="Path_431" data-name="Path 431" d="M15,13.5H12" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
    </g>
  </svg>`
      },
      {
        title: 'User and device info',
        content:
          'Get all the information you need about the user, the recording, and the divided being used. We provide you with the following information: date and time, user id, user name, user email, record id, mic toggle, duration, operation system, windows size, and browser.',
        img: 'https://assets.torecord.it/capture-add-info.png',
        sideImg: true,
        icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class='h-6 w-6' width="36" height="24" viewBox="0 0 36 24">
    <path id="Icon_material-devices" data-name="Icon material-devices" d="M6,9H33V6H6A3.009,3.009,0,0,0,3,9V25.5H0V30H21V25.5H6Zm28.5,3h-9A1.5,1.5,0,0,0,24,13.5v15A1.5,1.5,0,0,0,25.5,30h9A1.5,1.5,0,0,0,36,28.5v-15A1.5,1.5,0,0,0,34.5,12ZM33,25.5H27V15h6Z" transform="translate(0 -6)"/>
  </svg>`
      },
      {
        title: 'Console and network',
        content:
          'Seeing what happened on the front is not deficient to understand the full pitcher. To solve it better you need to see what happened during the recording and previous to that. We referring this stage as Investigate. Here are some of the things you can investigate: Console logs, errors, warnings, info, and Network get and response requests with their corresponding response.',
        img: 'https://assets.torecord.it/capture-network-console.png',
        sideImg: true,
        icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class='h-6 w-6' width="37.999" height="26.263" viewBox="0 0 37.999 26.263">
    <path id="Icon_awesome-terminal" data-name="Icon awesome-terminal" d="M15.317,16.619,3.778,28.158a1.425,1.425,0,0,1-2.015,0L.417,26.812a1.425,1.425,0,0,1,0-2.013L9.56,15.612.415,6.424a1.425,1.425,0,0,1,0-2.013L1.763,3.065a1.425,1.425,0,0,1,2.015,0L15.317,14.6A1.425,1.425,0,0,1,15.317,16.619ZM38,27.486v-1.9a1.425,1.425,0,0,0-1.425-1.425H18.525A1.425,1.425,0,0,0,17.1,25.586v1.9a1.425,1.425,0,0,0,1.425,1.425h18.05A1.425,1.425,0,0,0,38,27.486Z" transform="translate(0 -2.648)"/>
  </svg>`
      },
      {
        title: 'Your custom data',
        content:
          'We know that not all companies are the same, and some data is more crucial to you. Based on how you operate. It can be a request, order Id, or task ID it is easy to send any data to the recording and filter by the data you transfer to record to get everything you need to solve user issues after.',
        img: 'https://assets.torecord.it/capture-custom-data.png',
        sideImg: false,
        icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class='h-6 w-6' width="35.792" height="36" viewBox="0 0 35.792 36">
    <path id="Icon_material-perm-data-setting" data-name="Icon material-perm-data-setting" d="M28.485,17.25a12.387,12.387,0,0,1,1.5.1L30,0,0,30H17.34a12.387,12.387,0,0,1-.1-1.5A11.254,11.254,0,0,1,28.485,17.25ZM34.05,29.235a5.83,5.83,0,0,0,.06-.735,3.943,3.943,0,0,0-.06-.735l1.59-1.245a.39.39,0,0,0,.09-.48l-1.5-2.595a.38.38,0,0,0-.465-.165l-1.86.75a5.132,5.132,0,0,0-1.275-.735l-.285-1.98a.358.358,0,0,0-.36-.315h-3a.385.385,0,0,0-.375.315l-.285,1.98a5.992,5.992,0,0,0-1.275.735l-1.86-.75a.393.393,0,0,0-.465.165l-1.5,2.595a.371.371,0,0,0,.09.48l1.59,1.245a6.025,6.025,0,0,0,0,1.47l-1.59,1.245a.39.39,0,0,0-.09.48l1.5,2.595a.38.38,0,0,0,.465.165l1.86-.75a5.132,5.132,0,0,0,1.275.735l.285,1.98a.373.373,0,0,0,.375.315h3a.385.385,0,0,0,.375-.315l.285-1.98a5.544,5.544,0,0,0,1.26-.735l1.875.75a.393.393,0,0,0,.465-.165l1.5-2.595a.39.39,0,0,0-.09-.48ZM28.485,30.75a2.25,2.25,0,1,1,2.25-2.25A2.247,2.247,0,0,1,28.485,30.75Z"/>
  </svg>`
      },
      {
        title: 'Events & Pages',
        content:
          'The core of Record technology is to monitor user events. This way we can go deeper over what and where things happened. This data can help engineers and product teams to fast-spot issues the may effect a range of users.',
        img: 'https://assets.torecord.it/capture-pages.png',
        sideImg: false,
        icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class='h-6 w-6' width="19.375" height="31" viewBox="0 0 19.375 31">
    <path id="Icon_awesome-mouse-pointer" data-name="Icon awesome-mouse-pointer" d="M18.3,19.928H11.874l3.38,8.234a1.125,1.125,0,0,1-.572,1.453l-2.977,1.3a1.071,1.071,0,0,1-1.413-.588L7.08,22.5,1.833,27.9A1.071,1.071,0,0,1,0,27.124V1.108A1.071,1.071,0,0,1,1.833.33l17.22,17.712A1.11,1.11,0,0,1,18.3,19.928Z"/>
  </svg>`
      },
      {
        title: 'Window Capturing',
        content:
          'Gain a comprehensive understanding of a user\'s browser behavior, which can significantly aid in diagnosing and resolving any potential issues they might be encountering.',
        img: 'https://assets.torecord.it/window_object_photo.png',
        sideImg: false,
        icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class='h-6 w-6' width="19.5" height="27" viewBox="0 0 19.5 27">
        <path id="Icon_material-audiotrack" data-name="Icon material-audiotrack" d="M18,4.5V18.42A6.585,6.585,0,0,0,15.75,18a6.75,6.75,0,1,0,6.675,7.5H22.5V9h6V4.5Z" transform="translate(-9 -4.5)"/>
      </svg>`
      }
    ]
  },
  {
    name: 'Record Analytics',
    color: 'bg-tuto-secondary',
    icon: `<svg xmlns="http://www.w3.org/2000/svg" width="63.239" height="58.723" viewBox="0 0 63.239 58.723">
    <g id="Icon_ionic-md-analytics" data-name="Icon ionic-md-analytics" transform="translate(-2.25 -3.375)">
      <path id="Path_432" data-name="Path 432" d="M51.278,13.132,38.971,31.958A6.7,6.7,0,0,1,40.5,36.234a6.774,6.774,0,1,1-13.548,0,7.423,7.423,0,0,1,.113-1.256L18.931,30.25a6.732,6.732,0,0,1-4.7,1.891,6.812,6.812,0,0,1-3.331-.875l-8.651,8.1V47.44a6.041,6.041,0,0,0,6.026,6.026H59.463a6.041,6.041,0,0,0,6.026-6.026v-30l-8.27-5.49a6.769,6.769,0,0,1-5.941,1.185Z" transform="translate(0 8.632)" fill="#fff"/>
      <path id="Path_433" data-name="Path 433" d="M14.246,27.226A6.776,6.776,0,0,1,21.02,34c0,.3-.028.593-.056.875l8.482,4.742a6.714,6.714,0,0,1,4.29-1.524,7.088,7.088,0,0,1,1.468.155L47.538,19.139a6.7,6.7,0,0,1-1.256-3.923,6.774,6.774,0,1,1,13.548,0,7.362,7.362,0,0,1-.183,1.595l5.829,3.9V9.4a6.041,6.041,0,0,0-6.026-6.026H8.276A6.041,6.041,0,0,0,2.25,9.4V41.762L7.9,36.357a6.774,6.774,0,0,1,6.351-9.131Z" transform="translate(0 0)" fill="#fff"/>
    </g>
    </svg>`,
    description: '360-degree view for data-driven decisions.',
    video: 'https://assets.torecord.it/Second-short-website.mp4',
    route: 'analytics',
    tabs: [
      {
        title: 'User Emotion',
        content:
          'Our AI will highlight for you the emotion that your client uses to describe the issue. It will help you to understand how your client feels about your product and the situation and help you to approach him with the right attitude.',
        img: 'https://assets.torecord.it/analytics-emotions.png',
        sideImg: false,
        icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class='h-6 w-6' width="30" height="30" viewBox="0 0 30 30">
    <path id="Icon_material-insert-emoticon" data-name="Icon material-insert-emoticon" d="M17.985,3A15,15,0,1,0,33,18,14.993,14.993,0,0,0,17.985,3ZM18,30A12,12,0,1,1,30,18,12,12,0,0,1,18,30Zm5.25-13.5A2.25,2.25,0,1,0,21,14.25,2.247,2.247,0,0,0,23.25,16.5Zm-10.5,0a2.25,2.25,0,1,0-2.25-2.25A2.247,2.247,0,0,0,12.75,16.5ZM18,26.25A8.244,8.244,0,0,0,25.665,21H10.335A8.244,8.244,0,0,0,18,26.25Z" transform="translate(-3 -3)"/>
  </svg>`
      },
      {
        title: 'Automatic Tagging',
        content:
          'Our AI saves you time by understanding what your user is talking about and provides you with an automatic tagging base and the issue description. It will help you better classify and understand what needs to be done.',
        img: 'https://assets.torecord.it/analytics-auto-tag.png',
        sideImg: false,
        icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class='h-6 w-6' width="27.5" height="22" viewBox="0 0 27.5 22">
        <path id="Icon_awesome-tags" data-name="Icon awesome-tags" d="M21.4,9.708,12.292.6A2.062,2.062,0,0,0,10.833,0H2.063A2.062,2.062,0,0,0,0,2.063v8.771a2.062,2.062,0,0,0,.6,1.458l9.1,9.1a2.062,2.062,0,0,0,2.917,0L21.4,12.625a2.062,2.062,0,0,0,0-2.917ZM4.813,6.875A2.063,2.063,0,1,1,6.875,4.813,2.062,2.062,0,0,1,4.813,6.875ZM26.9,12.625,18.125,21.4a2.062,2.062,0,0,1-2.917,0l-.015-.015L22.672,13.9a3.867,3.867,0,0,0,0-5.469L14.24,0h2.093a2.062,2.062,0,0,1,1.458.6l9.1,9.1a2.062,2.062,0,0,1,0,2.917Z"/>
      </svg>`
      },
      {
        title: 'User Sentiment',
        content:
          'Using our AI model we find sentences with negative or positive sentiments. It will help you to classify if this issue needs to be resolved fast to keep the user and prevent churn.',
        img: 'https://assets.torecord.it/analytics-sentiment.png',
        sideImg: false,
        icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class='h-6 w-6' width="26.25" height="26.25" viewBox="0 0 26.25 26.25">
    <path id="Icon_awesome-thumbs-up" data-name="Icon awesome-thumbs-up" d="M5.332,11.484H1.23A1.23,1.23,0,0,0,0,12.715v12.3a1.23,1.23,0,0,0,1.23,1.23h4.1a1.23,1.23,0,0,0,1.23-1.23v-12.3A1.23,1.23,0,0,0,5.332,11.484ZM3.281,24.2a1.23,1.23,0,1,1,1.23-1.23A1.23,1.23,0,0,1,3.281,24.2ZM19.688,4.176c0,2.175-1.331,3.394-1.706,4.847H23.2A3.063,3.063,0,0,1,26.25,12a3.712,3.712,0,0,1-1,2.522l-.006.006A4.283,4.283,0,0,1,24.77,18.6a4.054,4.054,0,0,1-.84,3.833,2.722,2.722,0,0,1-.315,2.288c-1.046,1.5-3.64,1.525-5.833,1.525h-.146a14.718,14.718,0,0,1-6.13-1.627,8.069,8.069,0,0,0-2.7-.829.615.615,0,0,1-.6-.615V12.219a.615.615,0,0,1,.182-.437c2.031-2.007,2.9-4.132,4.569-5.8a6.209,6.209,0,0,0,1.3-3.02C14.484,2.015,14.961,0,16,0,17.227,0,19.688.41,19.688,4.176Z"/>
  </svg>`
      },
      {
        title: 'Manual Tag',
        content:
          'Tag your data according to your needs, it will help you to create your workflows and work faster based on your specific needs.',
        img: 'https://assets.torecord.it/analytics-manual-tag.png',
        sideImg: false,
        icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class='h-6 w-6' width="31.5" height="31.5" viewBox="0 0 31.5 31.5">
    <path id="Icon_awesome-hashtag" data-name="Icon awesome-hashtag" d="M30.984,12.8l.5-2.812A.844.844,0,0,0,30.656,9H25.4l1.028-5.758a.844.844,0,0,0-.831-.992H22.737a.844.844,0,0,0-.831.7L20.825,9H13.89l1.028-5.758a.844.844,0,0,0-.831-.992H11.23a.844.844,0,0,0-.831.7L9.318,9H3.757a.844.844,0,0,0-.831.7l-.5,2.813a.844.844,0,0,0,.831.992h5.26l-1.607,9H1.346a.844.844,0,0,0-.831.7l-.5,2.813A.844.844,0,0,0,.844,27H6.1L5.076,32.758a.844.844,0,0,0,.831.992H8.763a.844.844,0,0,0,.831-.7L10.675,27H17.61l-1.028,5.758a.844.844,0,0,0,.831.992H20.27a.844.844,0,0,0,.831-.7L22.182,27h5.561a.844.844,0,0,0,.831-.7l.5-2.813a.844.844,0,0,0-.831-.992h-5.26l1.607-9h5.561a.844.844,0,0,0,.831-.7Zm-12.57,9.7H11.479l1.607-9h6.935Z" transform="translate(0 -2.25)"/>
  </svg>`
      },
      {
        title: 'Transcript Summery',
        content:
          'Fast scan to see what the issue it’s all about in a few sentences. For better prioritization and efficiency.',
        img: 'https://assets.torecord.it/analytics-summary.png',
        sideImg: false,
        icon: `<svg xmlns="http://www.w3.org/2000/svg" class='h-6 w-6' width="27" height="33" viewBox="0 0 27 33">
    <g id="Icon_feather-file-text" data-name="Icon feather-file-text" transform="translate(-4.5 -1.5)">
      <path id="Path_427" data-name="Path 427" d="M21,3H9A3,3,0,0,0,6,6V30a3,3,0,0,0,3,3H27a3,3,0,0,0,3-3V12Z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
      <path id="Path_428" data-name="Path 428" d="M21,3v9h9" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
      <path id="Path_429" data-name="Path 429" d="M24,19.5H12" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
      <path id="Path_430" data-name="Path 430" d="M24,25.5H12" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
      <path id="Path_431" data-name="Path 431" d="M15,13.5H12" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
    </g>
  </svg>`
      }
    ]
  },
  {
    name: 'Privacy & Security',
    icon: `<svg xmlns="http://www.w3.org/2000/svg" width="63.239" height="58.723" viewBox="0 0 63.239 58.723">
    <g id="Icon_ionic-md-analytics" data-name="Icon ionic-md-analytics" transform="translate(-2.25 -3.375)">
      <path id="Path_432" data-name="Path 432" d="M51.278,13.132,38.971,31.958A6.7,6.7,0,0,1,40.5,36.234a6.774,6.774,0,1,1-13.548,0,7.423,7.423,0,0,1,.113-1.256L18.931,30.25a6.732,6.732,0,0,1-4.7,1.891,6.812,6.812,0,0,1-3.331-.875l-8.651,8.1V47.44a6.041,6.041,0,0,0,6.026,6.026H59.463a6.041,6.041,0,0,0,6.026-6.026v-30l-8.27-5.49a6.769,6.769,0,0,1-5.941,1.185Z" transform="translate(0 8.632)" fill="#fff"/>
      <path id="Path_433" data-name="Path 433" d="M14.246,27.226A6.776,6.776,0,0,1,21.02,34c0,.3-.028.593-.056.875l8.482,4.742a6.714,6.714,0,0,1,4.29-1.524,7.088,7.088,0,0,1,1.468.155L47.538,19.139a6.7,6.7,0,0,1-1.256-3.923,6.774,6.774,0,1,1,13.548,0,7.362,7.362,0,0,1-.183,1.595l5.829,3.9V9.4a6.041,6.041,0,0,0-6.026-6.026H8.276A6.041,6.041,0,0,0,2.25,9.4V41.762L7.9,36.357a6.774,6.774,0,0,1,6.351-9.131Z" transform="translate(0 0)" fill="#fff"/>
    </g>
    </svg>`,
    color: 'bg-tuto-primary',
    description: 'First.',
    video: 'https://assets.torecord.it/Second-short-website.mp4',
    route: 'privacy-security',
    tabs: [
      {
        title: 'Gdpr-Compliant Data Processor',
        content:
          'In order to be fully GDPR compliant, Record’s data is entirely securely stored on E.U, and for those who need it - we offer DPA (Data Processing Agreement). The data is stored in AWS Europe Ireland (eu-west-1) datacenter.',
        img: 'https://assets.torecord.it/privacy-gdpr.png',
        sideImg: false,
        icon: ''
      },
      {
        title: 'Masking & Blocking Customized For Your Product',
        content:
          'Apart from automatically hiding sensitive information, with Record you can choose what else needs to be blocked or masked in your product, within seconds. ',
        img: 'https://assets.torecord.it/privacy-masking.png',
        sideImg: false,
        icon: ''
      },
      {
        title: 'Users & Passwords',
        content:
          'For user authentication, Record uses Amazon Cognito. Amazon Cognito provides authentication, authorization, and user management for web and mobile apps. Data within Amazon Cognito is encrypted in accordance with industry standards. Amazon Cognito is compliant with SOC 1-3, PCI DSS, ISO 27001, and is HIPAA-BAA eligible.',
        img: 'https://assets.torecord.it/privacy-users.png',
        sideImg: false,
        icon: ''
      },
      {
        title: 'Application & Script Security',
        content:
          'Record uses SSL connection encryption (certificated by Amazon Web Services) across all our domains.',
        img: 'https://assets.torecord.it/privacy-app-security.png',
        sideImg: false,
        icon: ''
      }
    ]
  }
]
